import { createSlice } from '@reduxjs/toolkit'
import { modifier as modifierRef } from 'src/views/params/tauxPerso/tauxPersoSlice'

const initialState = [
  {
    name: 'aucun',
    a: 0,
    ref: 'aucun',
    b: 0,
  },
  {
    name: 'taux attendu n°1',
    a: 100,
    ref: 'taux_conc',
    b: 0,
  },
  {
    name: 'taux attendu n°2',
    a: 90,
    ref: 'taux_conc',
    b: 0.5,
  },
]
// model point list
export const txAttendusSlice = createSlice({
  name: 'tx_attendus',
  initialState,
  reducers: {
    supprimer: (state, action) =>
      state.filter((e) => !action.payload.map((mp) => mp.name).includes(e.name)),
    dupliquer: (state, action) => {
      var today = new Date()
      var time = today.toUTCString()
      return action.payload
        .map((e) => ({
          ...e,
          name: e.name + '_dupliqué_' + time,
        }))
        .concat(state)
    },
    modifier: (state, action) =>
      state.map((e) => (e.name === action.payload.old.name ? action.payload.new : e)),
    ajouter: (state, action) => [action.payload, ...state],
  },
  extraReducers: (builder) => {
    builder.addCase(modifierRef, (state, action) => {
      const oldName = action.payload.old.name
      const newName = action.payload.new.name
      if (oldName !== newName) {
        return state.map((e) => (e.ref === oldName ? { ...e, ref: newName } : e))
      } else {
        return state
      }
    })
  },
})

export const { supprimer, dupliquer, modifier, ajouter } = txAttendusSlice.actions
export const getTxAttendus = (state) => state.txAttendus

export default txAttendusSlice.reducer
