import { createSlice } from '@reduxjs/toolkit'
import { delFiles, addFile, addFiles } from '../rag/pdfFilesSlice'
import SteveImg from 'src/assets/images/avatars/copilot/steve.svg'
import JimImg from 'src/assets/images/avatars/copilot/jim.svg'
import AliceImg from 'src/assets/images/avatars/copilot/alice.svg'
import JaneImg from 'src/assets/images/avatars/copilot/jane.svg'
export const models = [
  {
    name: 'Jim',
    avatar: JimImg,
    title: 'Analyst',
    role: 'give a concise answer (5 sentences max).',
    color: 'primary',
    busy: false,
  },
  {
    name: 'Alice',
    avatar: AliceImg,
    title: 'Associate',
    role: 'give a concise answer.',
    color: 'info',
    busy: false,
  },
  {
    name: 'Jane',
    avatar: JaneImg,
    title: 'VP',
    role: 'give a detailed answer.',
    color: 'warning',
    busy: false,
  },
  {
    name: 'Steve',
    avatar: SteveImg,
    title: 'MD',
    role: 'give a detailed answer.',
    color: 'danger',
    busy: true,
  },
]

const initialState = {
  name: 'Jim',
  retriever: 'none',
}

export const copilotSlice = createSlice({
  name: 'copilot',
  initialState,
  reducers: {
    changeCopilot: (state, action) => (state = { ...state, name: action.payload }),
    setRetriever: (state, action) => (state = { ...state, retriever: action.payload }),
    delRetriever: (state) => (state = { ...state, retriever: 'none' }),
  },
  extraReducers: (builder) => {
    // if files change (add, delete, ...) reset the retriever (temporary solution). Ideally, we need to dynamically update the retriever...
    builder.addCase(delFiles, (state) => (state = { ...state, retriever: 'none' }))
    builder.addCase(addFiles, (state) => (state = { ...state, retriever: 'none' }))
    builder.addCase(addFile, (state) => (state = { ...state, retriever: 'none' }))
  },
})

export const { changeCopilot, setRetriever, delRetriever } = copilotSlice.actions
export const getCopilot = (state) => state.copilot

export default copilotSlice.reducer
