import { createSlice } from '@reduxjs/toolkit'

const zeros = new Array(20).fill(0)
const initialState = [
  {
    name: 'aucune',
    data: zeros,
  },
]
// liste des lois d'échéances
export const echeancesSlice = createSlice({
  name: 'echeances',
  initialState,
  reducers: {
    load: (state, action) => (state = action.payload),
    supprimer: (state, action) =>
      state.filter((e) => !action.payload.map((mp) => mp.name).includes(e.name)),
    dupliquer: (state, action) => {
      var today = new Date()
      var time = today.toUTCString()
      return action.payload
        .map((e) => ({
          ...e,
          name: e.name + '_copy_' + time,
        }))
        .concat(state)
    },
    modifier: (state, action) =>
      state.map((e) => (e.name === action.payload.old.name ? action.payload.new : e)),
    ajouter: (state, action) => [action.payload, ...state],
  },
})

export const { supprimer, dupliquer, modifier, ajouter, load } = echeancesSlice.actions
export const getEcheances = (state) => state.echeances

export default echeancesSlice.reducer
