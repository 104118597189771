import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RecursiveCharacterTextSplitter } from '@langchain/textsplitters'
import { Document } from 'langchain/document'

const textSplitter = new RecursiveCharacterTextSplitter({
  chunkSize: 1000,
  chunkOverlap: 200,
})

const extractText = async (file) => {
  const pdf = file.pdf
  const totalPageCount = pdf.numPages
  let docs = []
  for (var currentPage = 1; currentPage <= totalPageCount; currentPage++) {
    const page = pdf.getPage(currentPage) //a promise
    const pageContent = await page.then((page) =>
      page.getTextContent().then((text) =>
        text.items
          .map(function (s) {
            return s.str
          })
          .join(' '),
      ),
    )
    // for RAG
    const doc = new Document({
      pageContent: pageContent,
      metadata: { doc: file.name, page: currentPage },
    })
    docs.push(doc)
  }

  return docs
}

export const asyncSplits = createAsyncThunk('asyncSplits', async (file) => {
  // file = {name: ..., pdf: ...}
  const docs = await extractText(file)
  return {
    name: file.name,
    //text: text,
    docs: docs,
  }
})
const initialState = []

export const pdfFilesSlice = createSlice({
  name: 'pdfFiles',
  initialState,
  reducers: {
    delFiles: (state, action) =>
      state.filter((e) => !action.payload.map((file) => file.name).includes(e.file.name)),
    addFiles: (state, action) => {
      return [
        ...Array.from(action.payload).map((f) => ({
          file: f,
        })),
        //...action.payload,
        ...state,
      ]
    },
    addFile: (state, action) => [{ file: action.payload }, ...state],
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(asyncSplits.fulfilled, (state, action) =>
      state.map((e) =>
        e.file.name === action.payload.name ? { file: e.file, docs: action.payload.docs } : e,
      ),
    )
  },
})

export const { addFile, addFiles, delFiles } = pdfFilesSlice.actions
export const getPdfFiles = (state) => state.pdfFiles

export default pdfFilesSlice.reducer
