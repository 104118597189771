import { createSlice } from '@reduxjs/toolkit'
import { objFromArrays, uniqueList } from '../commun/UsefulFunc'
import { demoData } from './demoData'
import { modify as modifyPPB } from '../passif/ppb/ppbSlice'
import { update_all as updateParams } from 'src/views/params/generaux/paramGeneSlice'
import {
  supprimer as supprMp,
  dupliquer as duplMp,
  modifier as modifMp,
  ajouter as addMp,
} from 'src/views/passif/modelPoints/modelPointsSlice'

const initialState = demoData
const liab0 = {
  total_liability: 0,
  own_funds: 0,
  capital: 0,
  ret_earn: 0,
  last_earn: 0,
  res_capi: 0,
  sub_debts: 0,
  tech_prov: 0,
  ppb: 0,
  pre: 0,
  pm_no_ul: 0,
  pm_ps: 0,
  pm_wl: 0,
  pm_others: 0,
  pm_ul: 0,
  prc: 0,
  debts: 0,
  other_debts: 0,
  fiscal_debts: 0,
  short_term_debts: 0,
}
const asset0 = {
  total_asset: 0,
  investments: 0,
  intang_assets: 0,
  goodwill: 0,
  other_intang_assets: 0,
  property: 0,
  participation: 0,
  equity: 0,
  listed_equity: 0,
  unlisted_equity: 0,
  bond: 0,
  gov_bond: 0,
  corp_bond: 0,
  note: 0,
  col_sec: 0,
  ucits: 0,
  loan: 0,
  fixed_rate_loan: 0,
  float_rate_loan: 0,
  assets_ul: 0,
  receivables: 0,
  other_assets: 0,
  materials: 0,
  cash: 0,
}

const liabKeys = Object.keys(liab0)
const assetKeys = Object.keys(asset0)

const totalSideUpdate = (bsList, side) => {
  // total (all sections) updates for each side of the balance sheet
  // side can be 'asset' or 'liability'
  const keys = side === 'asset' ? assetKeys : liabKeys
  const res = objFromArrays(
    keys,
    keys.map((key) => bsList.slice(1).reduce((sum, current) => sum + current[side][key], 0)),
  )
  return res
}
// update liabiliy column when its grand-children change
const pmUpdate = (liab, pm) => {
  const pm_no_ul = pm.pm_ps + pm.pm_wl + pm.pm_others
  const tech_prov = liab.ppb + liab.pre + pm_no_ul + pm.pm_ul + liab.prc
  const updatedLiab = {
    ...liab,
    ...pm,
    pm_no_ul: pm_no_ul,
    tech_prov: tech_prov,
    total_liability: liab.own_funds + liab.sub_debts + tech_prov + liab.debts,
  }

  return updatedLiab
}
// update liability column when its children change
const liabUpdate = (liab) => {
  let updatedLiab = {
    ...liab,
    own_funds: liab.capital + liab.ret_earn + liab.last_earn + liab.res_capi,
    tech_prov: liab.ppb + liab.pre + liab.pm_no_ul + liab.pm_ul + liab.prc,
    debts: liab.other_debts + liab.fiscal_debts + liab.short_term_debts,
  }
  updatedLiab = {
    ...updatedLiab,
    total_liability:
      updatedLiab.own_funds + updatedLiab.sub_debts + updatedLiab.tech_prov + updatedLiab.debts,
  }
  return updatedLiab
}
// update asset column when its children change
const assetUpdate = (asset) => {
  let updatedAsset = {
    ...asset,
    intang_assets: asset.goodwill + asset.other_intang_assets,
    other_assets: asset.cash + asset.materials,
  }
  updatedAsset = {
    ...updatedAsset,
    total_asset:
      updatedAsset.intang_assets +
      updatedAsset.investments +
      updatedAsset.assets_ul +
      updatedAsset.receivables +
      updatedAsset.other_assets,
  }
  return updatedAsset
}

export const balanceSheetSlice = createSlice({
  name: 'balanceSheet',
  initialState,
  reducers: {
    sectionUpdate: (state, action) => {
      const inputs = action.payload
      let updatedLiab = objFromArrays(
        liabKeys,
        liabKeys.map((i) => parseFloat(inputs[i]) || 0),
      )
      updatedLiab = liabUpdate(updatedLiab)

      let updatedAsset = objFromArrays(
        assetKeys,
        assetKeys.map((i) => parseFloat(inputs[i]) || 0),
      )
      updatedAsset = assetUpdate(updatedAsset)

      const updatedSec = {
        section: inputs.section,
        liability: updatedLiab,
        asset: updatedAsset,
      }
      let newState = state.map((e) => (e.section === inputs.section ? updatedSec : e))
      const totalSec = {
        section: 'total',
        asset: totalSideUpdate(newState, 'asset'),
        liability: totalSideUpdate(newState, 'liability'),
      }
      newState = [totalSec, ...newState]
      return newState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(modifyPPB, (state, action) => {
      const which = action.payload.which
      if (which === 'stock') {
        const data = action.payload.data
        let newState = state.map((row) => ({
          ...row,
          liability: liabUpdate({
            ...row.liability,
            ppb: data.reduce((sum, current) => sum + current[row.section], 0),
          }),
        }))

        const totalSec = {
          section: 'total',
          asset: state[0].asset,
          liability: totalSideUpdate(newState, 'liability'),
        }
        newState = [totalSec, ...newState.slice(1)]

        return newState
      } else {
        return state
      }
    })
    builder.addCase(updateParams, (state, action) => {
      const oldSections = state.map((e) => e.section)
      const newSections = action.payload.sections.filter((e) => !oldSections.includes(e))
      const delSections = oldSections.filter(
        (e) => !['total', ...action.payload.sections].includes(e),
      )
      if (newSections.length > 0 || delSections.length > 0) {
        const newState = [
          ...state.filter((e) => !delSections.includes(e.section)),
          ...newSections.map((section) => ({
            section: section,
            liability: liab0,
            asset: asset0,
          })),
        ]
        return newState
      } else {
        return state
      }
    })

    builder.addCase(supprMp, (state, action) => {
      const removedMp = action.payload // [{section, type, pm}]
      const sections = uniqueList(removedMp.map((e) => e.section))
      const keys = ['ul', 'ps', 'wl', 'others']
      let newState = state
      sections.forEach((section) => {
        const bsSection = state.filter((e) => e.section === section)[0]
        const mps = removedMp.filter((e) => e.section === section)
        const pm = objFromArrays(
          keys.map((e) => 'pm_' + e),
          keys.map((key) =>
            mps
              .filter((e) => e.type === key)
              .reduce((sum, current) => sum - current.pm, bsSection.liability['pm_' + key]),
          ),
        )
        newState = newState.map((e) =>
          e.section === section
            ? {
                ...bsSection,
                liability: pmUpdate(bsSection.liability, pm),
              }
            : e,
        )
        const totalSec = {
          section: 'total',
          asset: newState[0].asset,
          liability: totalSideUpdate(newState, 'liability'),
        }
        newState = [totalSec, ...newState.slice(1)]
      })

      return newState
    })

    builder.addCase(duplMp, (state, action) => {
      const duplMp = action.payload.data // [{section, type, pm}]
      const sections = uniqueList(duplMp.map((e) => e.section))
      const keys = ['ul', 'ps', 'wl', 'others']
      let newState = state
      sections.forEach((section) => {
        const bsSection = state.filter((e) => e.section === section)[0]
        const mps = duplMp.filter((e) => e.section === section)
        const pm = objFromArrays(
          keys.map((e) => 'pm_' + e),
          keys.map((key) =>
            mps
              .filter((e) => e.type === key)
              .reduce((sum, current) => sum + current.pm, bsSection.liability['pm_' + key]),
          ),
        )
        newState = newState.map((e) =>
          e.section === section
            ? {
                ...bsSection,
                liability: pmUpdate(bsSection.liability, pm),
              }
            : e,
        )
        const totalSec = {
          section: 'total',
          asset: newState[0].asset,
          liability: totalSideUpdate(newState, 'liability'),
        }
        newState = [totalSec, ...newState.slice(1)]
      })

      return newState
    })

    builder.addCase(addMp, (state, action) => {
      const newMp = action.payload // {section, type, pm}
      const added = newMp.stock.reduce((sum, current) => sum + current.pm, 0)
      // which section and which LoB?
      let newState = state.map((e) =>
        e.section === newMp.section
          ? {
              ...e,
              liability: {
                ...e.liability,
                ['pm_' + newMp.type]: e.liability['pm_' + newMp.type] + added,
                pm_no_ul: newMp.type === 'ul' ? e.liability.pm_no_ul : e.liability.pm_no_ul + added,
                tech_prov: e.liability.tech_prov + added,
                total_liability: e.liability.total_liability + added,
              },
            }
          : e,
      )
      const totalSec = {
        section: 'total',
        asset: newState[0].asset,
        liability: totalSideUpdate(newState, 'liability'),
      }
      newState = [totalSec, ...newState.slice(1)]

      return newState
    })

    builder.addCase(modifMp, (state, action) => {
      const oldMp = action.payload.old
      const newMp = action.payload.new // {section, type, pm}
      const added = newMp.stock.reduce((sum, current) => sum + current.pm, 0)
      // which section and which LoB?
      let newState = state.map((e) =>
        e.section === newMp.section
          ? {
              ...e,
              liability: {
                ...e.liability,
                ['pm_' + newMp.type]: e.liability['pm_' + newMp.type] + added,
                pm_no_ul: newMp.type === 'ul' ? e.liability.pm_no_ul : e.liability.pm_no_ul + added,
                tech_prov: e.liability.tech_prov + added,
                total_liability: e.liability.total_liability + added,
              },
            }
          : e,
      )
      newState = newState.map((e) =>
        e.section === oldMp.section
          ? {
              ...e,
              liability: {
                ...e.liability,
                ['pm_' + oldMp.type]: e.liability['pm_' + oldMp.type] - oldMp.pm,
                pm_no_ul:
                  oldMp.type === 'ul' ? e.liability.pm_no_ul : e.liability.pm_no_ul - oldMp.pm,
                tech_prov: e.liability.tech_prov - oldMp.pm,
                total_liability: e.liability.total_liability - oldMp.pm,
              },
            }
          : e,
      )
      const totalSec = {
        section: 'total',
        asset: newState[0].asset,
        liability: totalSideUpdate(newState, 'liability'),
      }
      newState = [totalSec, ...newState.slice(1)]

      return newState
    })
  },
})

export const { sectionUpdate } = balanceSheetSlice.actions
export const getBalanceSheet = (state) => state.balanceSheet // in store

export default balanceSheetSlice.reducer
