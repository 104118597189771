import { createSlice } from '@reduxjs/toolkit'

const initialState = [
  {
    name: 'calcul annuel',
  },
  {
    name: 'ORSA',
    ots: [{ mp: 'Model point 1', ots: 'Taux cible n°2' }],
  },
]
// scenario eco det
export const stratFiSlice = createSlice({
  name: 'stratPbList',
  initialState,
  reducers: {
    importer: (state, action) => (state = [...state, action.payload]),
    dupliquer: (state, action) => {
      var today = new Date()
      var time = today.toUTCString()
      const duplicatedRows = action.payload.map((e) => ({
        ...e,
        name: e.name + '_dupliqué_' + time,
      }))
      return duplicatedRows.concat(state)
    },
    supprimer: (state, action) =>
      (state = state.filter((el) => !action.payload.map((e) => e.name).includes(el.name))),
    modifier: (state, action) => {
      // modifier ou ajouter
      const oldStrat = action.payload.old
      const inputs = action.payload.new
      const ots = oldStrat.ots.map((e) => ({ mp: e.mp, ots: inputs[e.mp] }))
      // gestion PPB
      const ppb = state[0].ppb.map((e) => ({
        section: e.section,
        floor: inputs['floor_' + e.section],
        cap: inputs['cap_' + e.section],
      }))
      //const ppb = { floor: inputs.floor, cap: inputs.cap }
      const newStrat = { name: inputs.name, ots: ots, ppb: ppb }
      // si modifier
      if (oldStrat.name !== '') {
        return state.map((e) => (e.name === oldStrat.name ? newStrat : e))
      } else {
        return [...state, newStrat]
      }
    },

    update: (state, action) =>
      (state = state.map((e) =>
        e.name === action.payload.name ? { ...e, [action.payload.key]: action.payload.value } : e,
      )),
  },
})

export const { importer, dupliquer, supprimer, modifier, update } = stratFiSlice.actions
export const getStratFiList = (state) => state.stratFiList // in store

export default stratFiSlice.reducer
