import { createSlice } from '@reduxjs/toolkit'

import { update_all } from 'src/views/params/generaux/paramGeneSlice'
const initialState = []
// model point list
export const modelPointsSlice = createSlice({
  name: 'modelPoints',
  initialState,
  reducers: {
    supprimer: (state, action) => {
      const names = action.payload.map((mp) => mp.name)
      return state.filter((e) => !names.includes(e.name))
    },
    dupliquer: (state, action) => {
      return action.payload.data
        .map((mp) => ({
          ...mp,
          name: mp.name + '_dupliqué_' + action.payload.time,
        }))
        .concat(state)
    },

    modifier: (state, action) => {
      // update pm and tmg (every time, even if not necessarily to be simple)
      const newMp = action.payload.new
      const pm = newMp.stock.reduce((sum, current) => sum + current.pm, 0)
      const ic = newMp.stock.reduce((sum, current) => sum + current.pm * current.tmg, 0)
      const tmg = (ic * 100) / pm
      return state.map((mp) =>
        mp.name === action.payload.old.name ? { ...newMp, pm: pm, tmg: tmg } : mp,
      )
    },
    ajouter: (state, action) => {
      const newMp = action.payload
      const pm = newMp.stock.reduce((sum, current) => sum + current.pm, 0)
      const ic = newMp.stock.reduce((sum, current) => sum + current.pm * current.tmg, 0)
      const tmg = (ic * 100) / pm
      return [{ ...newMp, pm: pm, tmg: tmg }, ...state]
    },
    updateMP: (state, action) => (state = action.payload),
  },
  extraReducers: (builder) => {
    builder.addCase(update_all, (state, action) => {
      const newSections = action.payload.sections

      return state.map((mp) =>
        newSections.includes(mp.section) ? mp : { ...mp, section: newSections[0] },
      )
    })
  },
})

export const { supprimer, dupliquer, modifier, ajouter, updateMP } = modelPointsSlice.actions
export const getModelPoints = (state) => state.modelPoints

export default modelPointsSlice.reducer
