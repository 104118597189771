import { createSlice } from '@reduxjs/toolkit'
import { cifFr, cifGb, cifVn } from '@coreui/icons'
//languages

export const langs = [
  { name: 'Français', value: 'fr', icon: cifFr },
  { name: 'English', value: 'en', icon: cifGb },
  { name: 'Tiếng Việt', value: 'vn', icon: cifVn },
]
const initialState = langs[0].value

export const langSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    setLang: (state, action) => (state = action.payload),
  },
})

export const { setLang } = langSlice.actions
export const getLang = (state) => state.lang

export default langSlice.reducer
