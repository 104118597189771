import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  relative: [],
  absolute: [],
  per_maturity: [],
}
// model point list
export const chocAssetSlice = createSlice({
  name: 'chocAssets',
  initialState,
  reducers: {
    load: (state, action) => (state = action.payload),
    supprimer: (state, action) =>
      state.filter((e) => !action.payload.map((row) => row.name).includes(e.name)),
    dupliquer: (state, action) => {
      var today = new Date()
      var time = today.toUTCString()
      return action.payload
        .map((titre) => ({
          ...titre,
          name: titre.code_val + '_dupliqué_' + time,
        }))
        .concat(state)
    },
    modifier: (state, action) => {
      const inputs = action.payload.new
      const res = {
        ...inputs,
      }
      return state.map((e) => (e.clef === action.payload.old.clef ? res : e))
    },
    ajouter: (state, action) => {
      if (Array.isArray(action.payload)) {
        const res = action.payload.map((inputs) => ({
          ...inputs,
        }))
        return res.concat(state)
      } else {
        const inputs = action.payload
        const res = {
          ...inputs,
        }
        return [res, ...state]
      }
    },
  },
})

export const { supprimer, dupliquer, modifier, ajouter, load } = chocAssetSlice.actions
export const getChocAssets = (state) => state.chocAssets

export default chocAssetSlice.reducer
