import { createSlice } from '@reduxjs/toolkit'

const initialState = [
  /*   {
    name: 'taux_conc',
    els: [
      { op: 'average', window: 5, ref: 'long_term_rate', desc: 'average_5_long_term_rate' },
      { op: 'average', window: 8, ref: 'long_term_rate', desc: 'average_8_long_term_rate' },
      { op: 'shift', window: 0, ref: 'long_term_rate', desc: 'shift_0_long_term_rate' },
    ],
    formula: 'min(1.5*x3, max(x1, x2))',
    desc: 'min(1.5*shift_0_long_term_rate, max(average_5_long_term_rate, average_8_long_term_rate))',
  }, */
]
// model point list
export const tauxPersoSlice = createSlice({
  name: 'taux_perso',
  initialState,
  reducers: {
    load: (state, action) => {
      const res = []
      for (const inputs of action.payload) {
        let desc = inputs.formula
        for (let i = 0; i < inputs.els.length; i++) {
          desc = desc.replace(
            'x' + (i + 1),
            inputs.els[i].op + '_' + inputs.els[i].window + '_' + inputs.els[i].ref,
          )
        }
        res.push({ ...inputs, desc: desc })
      }

      return res
    },
    supprimer: (state, action) =>
      state.filter((e) => !action.payload.map((mp) => mp.name).includes(e.name)),
    dupliquer: (state, action) => {
      var today = new Date()
      var time = today.toUTCString()
      return action.payload
        .map((e) => ({
          ...e,
          name: e.name + '_copy_' + time,
        }))
        .concat(state)
    },
    modifier: (state, action) =>
      state.map((e) => (e.name === action.payload.old.name ? action.payload.new : e)),
    ajouter: (state, action) => [action.payload, ...state],
  },
})

export const { supprimer, dupliquer, modifier, ajouter, load } = tauxPersoSlice.actions
export const getTauxPerso = (state) => state.tauxPerso

export default tauxPersoSlice.reducer
