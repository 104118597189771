import { createSlice } from '@reduxjs/toolkit'
import { dataDemo } from './dataDemo'

const initialState = [dataDemo]
// scenario eco det
export const scEcoSlice = createSlice({
  name: 'scEcoDet',
  initialState,
  reducers: {
    load: (state, action) => (state = action.payload),
    importer: (state, action) => (state = [...state, action.payload]), // action.payload = {name:..., data:...}
    dupliquer: (state, action) => {
      var today = new Date()
      var time = today.toUTCString()
      const duplicatedRows = action.payload.map((e) => ({
        ...e,
        name: e.name + '_dupliqué_' + time,
      }))
      return duplicatedRows.concat(state)
    },
    supprimer: (state, action) =>
      (state = state.filter((el) => !action.payload.map((e) => e.name).includes(el.name))),
    modifier: (state, action) =>
      state.map((e) => (e.name === action.payload.old.name ? action.payload.new : e)),
    ajouter: (state, action) => [action.payload, ...state],
  },
})

export const { importer, dupliquer, supprimer, modifier, ajouter, load } = scEcoSlice.actions
export const getScEcoList = (state) => state.scEcoList // in store

export default scEcoSlice.reducer
