import { createSlice } from '@reduxjs/toolkit'
import { modifier as modifierRef } from 'src/views/params/tauxPerso/tauxPersoSlice'

const initialState = [
  {
    name: 'défaut',
    prov: { a: 100, ref: 'taux_conc', b: 0 },
    target: { a: 100, ref: 'taux_conc', b: 0 },
    floor1: { a: 0, ref: 'aucun', b: 0 },
    floor2: { a: 0, ref: 'aucun', b: 0 },
    cap1: { a: 0, ref: 'aucun', b: 0 },
    cap2: { a: 0, ref: 'aucun', b: 0 },
  },
  {
    name: 'Taux cible n°1',
    prov: { a: 100, ref: 'taux_conc', b: 0 },
    target: { a: 100, ref: 'taux_conc', b: 0 },
    floor1: { a: 100, ref: 'taux_conc', b: 0 },
    floor2: { a: 50, ref: 'taux_conc', b: 0 },
    cap1: { a: 100, ref: 'taux_conc', b: 0 },
    cap2: { a: 150, ref: 'taux_conc', b: 0 },
  },
  {
    name: 'Taux cible n°2',
    prov: { a: 100, ref: 'taux_conc', b: 0 },
    target: { a: 110, ref: 'taux_conc', b: 0 },
    floor1: { a: 110, ref: 'aucun', b: 0 },
    floor2: { a: 50, ref: 'taux_conc', b: 0 },
    cap1: { a: 0, ref: 'aucun', b: 0 },
    cap2: { a: 150, ref: 'taux_conc', b: 0 },
  },
]
// scenario eco det
export const otsSlice = createSlice({
  name: 'otsList',
  initialState,
  reducers: {
    importer: (state, action) => (state = [...state, action.payload]), // action.payload = {name:..., ext:..., data:...}
    dupliquer: (state, action) => {
      var today = new Date()
      var time = today.toUTCString()
      const duplicatedRows = action.payload.map((e) => ({
        ...e,
        name: e.name + '_dupliqué_' + time,
      }))
      return duplicatedRows.concat(state)
    },
    supprimer: (state, action) => {
      const delNames = action.payload.map((e) => e.name).filter((e) => e !== 'défaut') // sauf 'défaut'
      return state.filter((el) => !delNames.includes(el.name))
    },
    modifier: (state, action) =>
      state.map((e) => (e.name === action.payload.old.name ? action.payload.new : e)),
    ajouter: (state, action) => [action.payload, ...state],
  },
  extraReducers: (builder) => {
    builder.addCase(modifierRef, (state, action) => {
      const oldName = action.payload.old.name
      const newName = action.payload.new.name
      if (oldName !== newName) {
        return state.map((ots) => ({
          name: ots.name,
          prov: ots.prov.ref === oldName ? { ...ots.prov, ref: newName } : ots.prov,
          target: ots.target.ref === oldName ? { ...ots.target, ref: newName } : ots.target,
          floor1: ots.floor1.ref === oldName ? { ...ots.floor1, ref: newName } : ots.floor1,
          floor2: ots.floor2.ref === oldName ? { ...ots.floor2, ref: newName } : ots.floor2,
          cap1: ots.cap1.ref === oldName ? { ...ots.cap1, ref: newName } : ots.cap1,
          cap2: ots.cap2.ref === oldName ? { ...ots.cap2, ref: newName } : ots.cap2,
        }))
      } else {
        return state
      }
    })
  },
})

export const { importer, dupliquer, supprimer, modifier, ajouter } = otsSlice.actions
export const getOtsList = (state) => state.otsList // in store

export default otsSlice.reducer
