import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  //show: false,
  data: [],
}

export const excelSlice = createSlice({
  name: 'excel',
  initialState,
  reducers: {
    //changeExcelVisible: (state) => ({ show: !state.show, data: state.data }),
    //setExcelVisible: (state, action) => ({ show: action.payload, data: state.data }),
    delExcelData: (state) => ({ data: [] }),
    setExcelData: (state, action) => ({ data: action.payload }),
  },
})

export const { delExcelData, setExcelData } = excelSlice.actions
export const getExcelData = (state) => state.excel.data
//export const getExcelVisible = (state) => state.excel.show

export default excelSlice.reducer
