import { createSlice } from '@reduxjs/toolkit'
import { ptfActifDemo } from './dataDemo'
import { get_init_market_value, get_init_book_value, get_clef, check_validity } from './functions'
const initialState = ptfActifDemo.map((e) => ({
  ...e,
  clef: get_clef(e),
  book_value: get_init_book_value(e),
  market_value: get_init_market_value(e),
  erreurs: check_validity(e),
}))
// model point list
export const actifSlice = createSlice({
  name: 'actifs',
  initialState,
  reducers: {
    loadAsset: (state, action) =>
      (state = action.payload.map((inputs) => ({
        ...inputs,
        clef: get_clef(inputs),
        book_value: get_init_book_value(inputs),
        market_value: get_init_market_value(inputs),
        erreurs: check_validity(inputs),
      }))),
    supprimer: (state, action) =>
      state.filter((e) => !action.payload.map((mp) => mp.clef).includes(e.clef)),
    dupliquer: (state, action) => {
      var today = new Date()
      var time = today.toUTCString()
      return action.payload
        .map((titre) => ({
          ...titre,
          code_val: titre.code_val + '_dupliqué_' + time,
          label: titre.label + '_dupliqué_' + time,
          clef: titre.code_val + '_dupliqué_' + time + '__' + titre.section,
          comment:
            time +
            ': copie du titre ' +
            titre.label +
            ' de code valeur ' +
            titre.code_val +
            ' de la section ' +
            titre.section +
            '.',
        }))
        .concat(state)
    },
    modifier: (state, action) => {
      const inputs = action.payload.new
      const res = {
        ...inputs,
        clef: get_clef(inputs),
        book_value: get_init_book_value(inputs),
        market_value: get_init_market_value(inputs),
        erreurs: check_validity(inputs),
      }
      return state.map((e) => (e.clef === action.payload.old.clef ? res : e))
    },
    ajouter: (state, action) => {
      if (Array.isArray(action.payload)) {
        const res = action.payload.map((inputs) => ({
          ...inputs,
          clef: get_clef(inputs),
          book_value: get_init_book_value(inputs),
          market_value: get_init_market_value(inputs),
          erreurs: check_validity(inputs),
        }))
        return res.concat(state)
      } else {
        const inputs = action.payload
        const res = {
          ...inputs,
          clef: get_clef(inputs),
          book_value: get_init_book_value(inputs),
          market_value: get_init_market_value(inputs),
          erreurs: check_validity(inputs),
        }
        return [res, ...state]
      }
    },
  },
})

export const { supprimer, dupliquer, modifier, ajouter, loadAsset } = actifSlice.actions
export const getActifs = (state) => state.actifs

export default actifSlice.reducer
