import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CButton,
  CToast,
  CToastHeader,
  CToastBody,
  CBadge,
  CAlert,
  CAlertHeading,
  CCol,
  CRow,
  CProgress,
  CProgressBar,
  CTooltip,
} from '@coreui/react'
import {
  cilTranslate,
  cilSettings,
  cilFullscreen,
  cilFullscreenExit,
  cilGraph,
  cilLoop,
  cilSpreadsheet,
  cibPython,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import loadingIcon from 'src/assets/images/loading.gif'
import { percentFormatter, capitalized } from './UsefulFunc'
import { getLang } from 'src/components/slices/langSlice'
import { getLabels } from 'src/components/slices/labelsSlice'

export const DateMessage = () => {
  const lang = useSelector(getLang)
  if (lang === 'fr') {
    return (
      <>
        A titre de convenance, les dates sont affichées au format français{' '}
        <CBadge color="danger">dd/mm/yyyy</CBadge> mais stockées au format international{' '}
        <CBadge color="success">yyyy-mm-dd</CBadge>. Les données Date importées/exportées suivent ce
        dernier format par défaut. Néanmoins, si les dates sont au format français dans vos fichiers
        Input, Solvencii s&apos;occupera de la conversion.
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        For convenience, dates are displayed in French format{' '}
        <CBadge color="danger">dd/mm/yyyy</CBadge> but stored in international format{' '}
        <CBadge color="success">yyyy-mm-dd</CBadge>. Date data imported/exported follows this last
        default format. However, if the dates are in French format in your files Input, Solvencii
        will take care of the conversion.
      </>
    )
  } else {
    return (
      <>
        Để việc hiển thị ngày tháng được nhất quán giữa các nước, ngày được hiển thị ở định dạng
        tiếng Pháp <CBadge color="danger">dd/mm/yyyy</CBadge> nhưng được lưu trữ ở định dạng quốc tế{' '}
        <CBadge color="success">yyyy-mm-dd</CBadge>. Dữ liệu ngày được nhập/xuất tuân theo điều này
        định dạng mặc định cuối cùng. Tuy nhiên, nếu ngày ở định dạng tiếng Pháp trong tệp của bạn
        Đầu vào, Solvencii sẽ đảm nhiệm việc chuyển đổi.
      </>
    )
  }
}

export const MessageToast = (type, title, message) => {
  var today = new Date()
  var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
  return (
    <CToast title={title}>
      <CToastHeader className={'bg-' + type + ' bg-opacity-50 text-dark'} closeButton>
        <strong className="me-auto">{title}</strong>
        <small>{time}</small>
      </CToastHeader>
      <CToastBody className={'bg-' + type + ' bg-opacity-10 text-dark'}>{message}</CToastBody>
    </CToast>
  )
}

export const LoadingIcon = <img src={loadingIcon} height={50} />

export const HeaderMessage = () => {
  const lang = useSelector(getLang)
  if (lang === 'fr') {
    return (
      <>
        Les fichiers importés peuvent avoir en en-tête : les libellés Solvencii, des codes
        Solvencii, ou autres noms (auquel cas vous devez préciser dans la table de correspondance
        quelle colonne correspond à quelle donnée). Pour voir la table de correspondance, cliquer
        sur l&apos;icône
        <CIcon icon={cilTranslate} size="cumtom" className="mx-2" />à droite
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        Imported files can have in the header either Solvencii labels, Solvencii codes, or other
        names (in which case you must specify in the correspondence table which column corresponds
        to which data). To see the correspondence table, click on the icon
        <CIcon icon={cilTranslate} size="cumtom" className="mx-2" />
        on the right side
      </>
    )
  } else {
    return (
      <>
        Định dạng tiêu đề của các tệp đã nhập có thể là : tên Solvencii, mã Solvencii hoặc tên khác
        (trong trường hợp này bạn phải ghi rõ trong bảng tương ứng cột nào tương ứng với dữ liệu
        nào). Để xem bảng tương ứng, vui lòng nhấn vào biểu tượng
        <CIcon icon={cilTranslate} size="cumtom" className="mx-2" />ở bên phải
      </>
    )
  }
}

/* eslint-disable react/prop-types */
export const DataAndGraph = ({ inputs, Table, Graph, dis }) => {
  const labels = useSelector(getLabels)
  const [fullScreen, setFullScreen] = useState(false)

  return (
    <>
      <CRow>
        <CCol lg={fullScreen ? 12 : 5}>
          <CAlert color="light">
            <CAlertHeading component="h5">
              <CIcon size="lg" icon={cilSettings} title="parametres" /> Données{' '}
              <CButton
                className="ms-2 float-end"
                color="dark"
                variant="ghost"
                shape="rounded-pill"
                onClick={() => setFullScreen((e) => !e)}
              >
                <CIcon
                  size="lg"
                  icon={fullScreen ? cilFullscreenExit : cilFullscreen}
                  title="parametres"
                />
              </CButton>
            </CAlertHeading>
            <hr />
            <CRow>
              <Table inputs={inputs} fullScreen={fullScreen} dis={dis} />
            </CRow>
          </CAlert>
        </CCol>
        <CCol lg={fullScreen ? 12 : 7}>
          <CAlert color="light">
            <CAlertHeading component="h5">
              <CIcon size="lg" icon={cilGraph} title="general" /> {labels.graph}
            </CAlertHeading>
            <hr />
            <CCol className="me-2">
              <Graph inputs={inputs} />
            </CCol>
          </CAlert>
        </CCol>
      </CRow>
    </>
  )
}

export const TxPMVLRenderer = (params) => {
  const res = Math.abs(params.value) * 100
  return (
    <CProgress className="mb-1 mt-0 mx-auto" height={40}>
      <CProgressBar color={params.value > 0 ? 'success' : 'danger'} value={res}>
        {res >= 50 && '\xa0' + percentFormatter(params)}
      </CProgressBar>
      {res < 50 && '\xa0' + percentFormatter(params)}
    </CProgress>
  )
}

export const CheckRenderer = (params) => {
  const labels = useSelector(getLabels)
  return <>{params.value === 0 ? <></> : <CBadge color="danger ">{labels.errors}</CBadge>}</>
}
