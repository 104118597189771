export const demoData = [
  {
    section: 'total',
    liability: {
      total_liability: 1320000,
      own_funds: 114000,
      capital: 80000,
      ret_earn: 18000,
      last_earn: 6000,
      res_capi: 10000,
      sub_debts: 0,
      tech_prov: 1202000,
      ppb: 32000,
      pre: 0,
      pm_no_ul: 1170000,
      pm_ps: 1170000,
      pm_wl: 0,
      pm_others: 0,
      pm_ul: 0,
      prc: 0,
      debts: 4000,
      other_debts: 4000,
      fiscal_debts: 0,
      short_term_debts: 0,
    },
    asset: {
      total_asset: 1320000,
      investments: 1177000,
      intang_assets: 70000,
      goodwill: 60000,
      other_intang_assets: 10000,
      property: 122000,
      participation: 0,
      equity: 150000,
      listed_equity: 150000,
      unlisted_equity: 0,
      bond: 905000,
      gov_bond: 0,
      corp_bond: 905000,
      note: 0,
      col_sec: 0,
      ucits: 0,
      loan: 0,
      //fixed_rate_loan: 0,
      //float_rate_loan: 0,
      assets_ul: 0,
      receivables: 20000,
      other_assets: 0,
      materials: 10000,
      cash: 43000,
    },
  },
  {
    section: 'canton general',
    liability: {
      total_liability: 1320000,
      own_funds: 114000,
      capital: 80000,
      ret_earn: 18000,
      last_earn: 6000,
      res_capi: 10000,
      sub_debts: 0,
      tech_prov: 1202000,
      ppb: 32000,
      pre: 0,
      pm_no_ul: 1170000,
      pm_ps: 1170000,
      pm_wl: 0,
      pm_others: 0,
      pm_ul: 0,
      prc: 0,
      debts: 4000,
      other_debts: 4000,
      fiscal_debts: 0,
      short_term_debts: 0,
    },
    asset: {
      total_asset: 1320000,
      investments: 1177000,
      intang_assets: 70000,
      goodwill: 60000,
      other_intang_assets: 10000,
      property: 122000,
      participation: 0,
      equity: 150000,
      listed_equity: 150000,
      unlisted_equity: 0,
      bond: 905000,
      gov_bond: 0,
      corp_bond: 905000,
      note: 0,
      col_sec: 0,
      ucits: 0,
      loan: 0,
      //fixed_rate_loan: 0,
      //float_rate_loan: 0,
      assets_ul: 0,
      receivables: 20000,
      other_assets: 0,
      materials: 10000,
      cash: 43000,
    },
  },
  {
    section: 'PER',
    liability: {
      total_liability: 0,
      own_funds: 0,
      capital: 0,
      ret_earn: 0,
      last_earn: 0,
      res_capi: 0,
      sub_debts: 0,
      tech_prov: 0,
      ppb: 0,
      pre: 0,
      pm_no_ul: 0,
      pm_ps: 0,
      pm_wl: 0,
      pm_others: 0,
      pm_ul: 0,
      prc: 0,
      debts: 0,
      other_debts: 0,
      fiscal_debts: 0,
      short_term_debts: 0,
    },
    asset: {
      total_asset: 0,
      investments: 0,
      intang_assets: 0,
      goodwill: 0,
      other_intang_assets: 0,
      property: 0,
      participation: 0,
      equity: 0,
      listed_equity: 0,
      unlisted_equity: 0,
      bond: 0,
      gov_bond: 0,
      corp_bond: 0,
      note: 0,
      col_sec: 0,
      ucits: 0,
      loan: 0,
      //fixed_rate_loan: 0,
      //float_rate_loan: 0,
      assets_ul: 0,
      receivables: 0,
      other_assets: 0,
      materials: 0,
      cash: 0,
    },
  },
  {
    section: 'PERP',
    liability: {
      total_liability: 0,
      own_funds: 0,
      capital: 0,
      ret_earn: 0,
      last_earn: 0,
      res_capi: 0,
      sub_debts: 0,
      tech_prov: 0,
      ppb: 0,
      pre: 0,
      pm_no_ul: 0,
      pm_ps: 0,
      pm_wl: 0,
      pm_others: 0,
      pm_ul: 0,
      prc: 0,
      debts: 0,
      other_debts: 0,
      fiscal_debts: 0,
      short_term_debts: 0,
    },
    asset: {
      total_asset: 0,
      investments: 0,
      intang_assets: 0,
      goodwill: 0,
      other_intang_assets: 0,
      property: 0,
      participation: 0,
      equity: 0,
      listed_equity: 0,
      unlisted_equity: 0,
      bond: 0,
      gov_bond: 0,
      corp_bond: 0,
      note: 0,
      col_sec: 0,
      ucits: 0,
      loan: 0,
      //fixed_rate_loan: 0,
      //float_rate_loan: 0,
      assets_ul: 0,
      receivables: 0,
      other_assets: 0,
      materials: 0,
      cash: 0,
    },
  },
]
