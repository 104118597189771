import { actifs } from 'src/views/actif/const'
// pour les inputs actif
export const check_validity = (titre) => {
  let erreurs = {}
  function check_empty(item) {
    if (titre[item] === undefined || titre[item].trim() === '')
      erreurs = { ...erreurs, [item]: 'valeur non-autorisée' }
  }
  ;['code_val', 'section'].forEach((item) => check_empty(item))

  if (!actifs.includes(titre.cat)) {
    erreurs = { ...erreurs, cat: 'catégorie non reconnue' }
  } else {
  }
  return erreurs
}
export const get_init_market_value = (asset) =>
  (parseFloat(asset?.quantity) || 1) *
  parseFloat(asset.quote) *
  (parseFloat(asset.nominal) / 100 || 1)
export const get_init_book_value = (asset) => {
  if (['fixed_rate_bond', 'float_rate_bond', 'infl_index_bond'].includes(asset.cat)) {
    return parseFloat(asset.cost_price) + parseFloat(asset.premium)
  }
  if (asset.cat === 'equity') {
    return parseFloat(asset.cost_price) - parseFloat(asset.pdd_init)
  }
  if (asset.cat === 'property') {
    return (
      parseFloat(asset.acqui_cost) +
      parseFloat(asset.constr) +
      parseFloat(asset.land) -
      parseFloat(asset.pdd_init) -
      parseFloat(asset.amort_acqui_cost_init) -
      parseFloat(asset.amort_constr_init)
    )
  }
}
export const get_clef = (asset) => asset.code_val + '__' + asset.section
