import { createSlice } from '@reduxjs/toolkit'
import {
  ajouter as ajouterMp,
  modifier as modifierMp,
  dupliquer as dupliquerMp,
  supprimer as supprimerMp,
} from 'src/views/passif/modelPoints/modelPointsSlice'
import { modifier as modifierFrais, supprimer as supprimerFrais } from './fraisSlice'

const initialState = [
  {
    name: 'Hypothèse de Frais n°1',
    hyp: [
      {
        mp: 'Model point 1',
        frais: 'Frais Euro',
      },
    ],
  },
  {
    name: 'Hypothèse de Frais n°2',
    hyp: [
      {
        mp: 'Model point 1',
        frais: 'défaut',
      },
    ],
  },
]
// scenario eco det
export const hypFraisSlice = createSlice({
  name: 'hypFraisList',
  initialState,
  reducers: {
    importer: (state, action) => (state = [...state, action.payload]),
    dupliquer: (state, action) => {
      var today = new Date()
      var time = today.toUTCString()
      const duplicatedRows = action.payload.map((e) => ({
        ...e,
        name: e.name + '_dupliqué_' + time,
      }))
      return duplicatedRows.concat(state)
    },
    supprimer: (state, action) =>
      (state = state.filter((el) => !action.payload.map((e) => e.name).includes(el.name))),
    modifier: (state, action) => {
      // modifier ou ajouter
      const oldHyp = action.payload.old
      const inputs = action.payload.new
      const hyp = oldHyp.hyp.map((e) => ({ mp: e.mp, frais: inputs[e.mp] }))
      const newHyp = { name: inputs.name, hyp: hyp }
      // si modifier
      if (oldHyp.name !== '') {
        return state.map((e) => (e.name === oldHyp.name ? newHyp : e))
      } else {
        return [...state, newHyp]
      }
    },

    update: (state, action) =>
      (state = state.map((e) =>
        e.name === action.payload.name ? { ...e, [action.payload.key]: action.payload.value } : e,
      )),
  },
  extraReducers: (builder) => {
    builder.addCase(ajouterMp, (state, action) =>
      state.map((e) => ({
        name: e.name,
        hyp: [
          ...e.hyp,
          {
            mp: action.payload.name,
            frais: 'défaut',
          },
        ],
      })),
    )
    builder.addCase(modifierMp, (state, action) =>
      action.payload.old.name === action.payload.new.name
        ? state
        : state.map((e) => ({
            name: e.name,
            hyp: e.hyp.map((row) =>
              row.mp === action.payload.old.name
                ? { mp: action.payload.new.name, frais: row.frais }
                : row,
            ),
          })),
    )
    builder.addCase(dupliquerMp, (state, action) => {
      const mpSource = action.payload.data.map((e) => e.name)
      return state.map((e) => ({
        name: e.name,
        hyp: [
          ...e.hyp
            .filter((row) => mpSource.includes(row.mp))
            .map((row) => ({ mp: row.mp + '_dupliqué_' + action.payload.time, frais: row.frais })),
          ...e.hyp,
        ],
      }))
    })
    builder.addCase(supprimerMp, (state, action) => {
      const delNames = action.payload.map((e) => e.name)
      return state.map((e) => ({
        name: e.name,
        hyp: [...e.hyp.filter((row) => !delNames.includes(row.mp))],
      }))
    })

    builder.addCase(supprimerFrais, (state, action) => {
      const delNames = action.payload.map((e) => e.name).filter((e) => e !== 'défaut') // sauf 'défaut'
      return state.map((e) => ({
        name: e.name,
        hyp: [
          ...e.hyp.map((row) =>
            delNames.includes(row.frais) ? { mp: row.mp, frais: 'défaut' } : row,
          ),
        ],
      }))
    })

    builder.addCase(modifierFrais, (state, action) =>
      action.payload.old.name === action.payload.new.name
        ? state
        : state.map((e) => ({
            name: e.name,
            hyp: e.hyp.map((row) =>
              row.frais === action.payload.old.name
                ? { mp: row.mp, frais: action.payload.new.name }
                : row,
            ),
          })),
    )
  },
})

export const {
  importer,
  dupliquer,
  supprimer,
  modifier,
  update,
  ajouterMpFrais,
  modifierMpFrais,
  dupliquerMpFrais,
} = hypFraisSlice.actions
export const getHypFraisList = (state) => state.hypFraisList // in store

export default hypFraisSlice.reducer
