import { createSlice } from '@reduxjs/toolkit'

const initialState = [
  {
    name: 'aucune',
    data: [{ 2023: 0 }],
  },
]
// model point list
export const decesSlice = createSlice({
  name: 'décès',
  initialState,
  reducers: {
    load: (state, action) => (state = action.payload),
    supprimer: (state, action) =>
      state.filter((e) => !action.payload.map((mp) => mp.name).includes(e.name)),
    dupliquer: (state, action) => {
      var today = new Date()
      var time = today.toUTCString()
      return action.payload
        .map((e) => ({
          ...e,
          name: e.name + '_dupliqué_' + time,
        }))
        .concat(state)
    },
    modifier: (state, action) =>
      state.map((e) => (e.name === action.payload.old.name ? action.payload.new : e)),
    ajouter: (state, action) => [action.payload, ...state],
  },
})

export const { supprimer, dupliquer, modifier, ajouter, load } = decesSlice.actions
export const getDeces = (state) => state.deces

export default decesSlice.reducer
