import { createSlice } from '@reduxjs/toolkit'
import {
  ajouter as ajouterMp,
  modifier as modifierMp,
  dupliquer as dupliquerMp,
  supprimer as supprimerMp,
} from 'src/views/passif/modelPoints/modelPointsSlice'
import { supprimer as supprimerOts, modifier as modifierOts } from './otsSlice'

const initialState = [
  {
    name: 'calcul annuel',
    ots: [
      {
        mp: 'Model point 1',
        ots: 'Taux cible n°1',
      },
    ],
    ppb: [
      {
        section: 'canton general',
        floor: 0,
        cap: 20,
      },
      {
        section: 'PER',
        floor: 0,
        cap: 10,
      },
      {
        section: 'PERP',
        floor: 0,
        cap: 15,
      },
    ],
  },
  {
    name: 'ORSA',
    ots: [{ mp: 'Model point 1', ots: 'Taux cible n°2' }],
    ppb: [
      {
        section: 'canton general',
        floor: 0,
        cap: 20,
      },
      {
        section: 'PER',
        floor: 0,
        cap: 20,
      },
      {
        section: 'PERP',
        floor: 0,
        cap: 20,
      },
    ],
  },
]
// scenario eco det
export const stratPbSlice = createSlice({
  name: 'stratPbList',
  initialState,
  reducers: {
    importer: (state, action) => (state = [...state, action.payload]),
    dupliquer: (state, action) => {
      var today = new Date()
      var time = today.toUTCString()
      const duplicatedRows = action.payload.map((e) => ({
        ...e,
        name: e.name + '_dupliqué_' + time,
      }))
      return duplicatedRows.concat(state)
    },
    supprimer: (state, action) =>
      (state = state.filter((el) => !action.payload.map((e) => e.name).includes(el.name))),
    modifier: (state, action) => {
      // modifier ou ajouter
      const oldStrat = action.payload.old
      const inputs = action.payload.new
      const ots = oldStrat.ots.map((e) => ({ mp: e.mp, ots: inputs[e.mp] }))
      // gestion PPB
      const ppb = state[0].ppb.map((e) => ({
        section: e.section,
        floor: inputs['floor_' + e.section],
        cap: inputs['cap_' + e.section],
      }))
      //const ppb = { floor: inputs.floor, cap: inputs.cap }
      const newStrat = { name: inputs.name, ots: ots, ppb: ppb }
      // si modifier
      if (oldStrat.name !== '') {
        return state.map((e) => (e.name === oldStrat.name ? newStrat : e))
      } else {
        return [...state, newStrat]
      }
    },

    update: (state, action) =>
      (state = state.map((e) =>
        e.name === action.payload.name ? { ...e, [action.payload.key]: action.payload.value } : e,
      )),
  },

  extraReducers: (builder) => {
    builder.addCase(ajouterMp, (state, action) =>
      state.map((e) => ({
        ...e,
        ots: [
          ...e.ots,
          {
            mp: action.payload.name,
            ots: 'défaut',
          },
        ],
      })),
    )
    builder.addCase(modifierMp, (state, action) =>
      action.payload.old.name === action.payload.new.name
        ? state
        : state.map((e) => ({
            ...e,
            ots: e.ots.map((row) =>
              row.mp === action.payload.old.name
                ? { mp: action.payload.new.name, ots: row.ots }
                : row,
            ),
          })),
    )
    builder.addCase(dupliquerMp, (state, action) => {
      const mpSource = action.payload.data.map((e) => e.name)
      return state.map((e) => ({
        ...e,
        ots: [
          ...e.ots
            .filter((row) => mpSource.includes(row.mp))
            .map((row) => ({ mp: row.mp + '_dupliqué_' + action.payload.time, ots: row.ots })),
          ...e.ots,
        ],
      }))
    })
    builder.addCase(supprimerMp, (state, action) => {
      const delNames = action.payload.map((e) => e.name)
      return state.map((e) => ({
        name: e.name,
        ots: [...e.ots.filter((row) => !delNames.includes(row.mp))],
      }))
    })

    builder.addCase(supprimerOts, (state, action) => {
      const delNames = action.payload.map((e) => e.name).filter((e) => e !== 'défaut') // sauf 'défaut'
      return state.map((e) => ({
        ...e,
        ots: [
          ...e.ots.map((row) => (delNames.includes(row.ots) ? { mp: row.mp, ots: 'défaut' } : row)),
        ],
      }))
    })

    builder.addCase(modifierOts, (state, action) =>
      action.payload.old.name === action.payload.new.name
        ? state
        : state.map((e) => ({
            ...e,
            ots: e.ots.map((row) =>
              row.ots === action.payload.old.name
                ? { mp: row.mp, ots: action.payload.new.name }
                : row,
            ),
          })),
    )
  },
})

export const { importer, dupliquer, supprimer, modifier, update } = stratPbSlice.actions
export const getStratPbList = (state) => state.stratPbList // in store

export default stratPbSlice.reducer
