import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: false,
}

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    changeShow: (state, action) => ({ ...state, sidebarShow: action.payload }),
    changeFoldable: (state) => ({ ...state, sidebarUnfoldable: !state.sidebarUnfoldable }),
  },
})

// Action creators are generated for each case reducer function
export const { changeFoldable, changeShow } = sidebarSlice.actions
export const selectSidebar = (state) => state.sidebar

export default sidebarSlice.reducer
