// nom de colonnes/variables python
export const pyVar = [
  { value: 'month', format: 'numeric' },
  { value: 'date', format: 'date' },
  { value: 'year', format: 'numeric' },
  { value: 'code_val' },
  { value: 'label' },
  { value: 'section' },
  { value: 'cat' },
  { value: 'sub_cat' },
  { value: 'rating' },
  { value: 'quantity' },
  { value: 'nominal' },
  { value: 'quote' },
  { value: 'maturity', format: 'date' },
  { value: 'YTM_cost_price' },
  { value: 'beta' },
  { value: 'cat_s2_spd', label: 'S2 Cat - Spread' },
  { value: 'cat_s2_eq', label: 'S2 Cat - Action' },
  { value: 'cat_s2_prop', label: 'S2 Cat - Immobilier' },
  { value: 'market_value' },
  { value: 'book_value' },
  { value: 'cost_price' },
  { value: 'premium' },
  { value: 'pdd' },
  { value: 'running_coupon' },
  { value: 'unrealized_pnl' },
  { value: 'recognized_revenue' },
  { value: 'received_revenue' },
  { value: 'realized_pnl' },
  { value: 'fees' },
  { value: 'var_premium' },
  { value: 'premium_sell_buy' },
  { value: 'var_running_coupon' },
  { value: 'running_coupon_sell_buy' },
  { value: 'var_pdd' },
  { value: 'var_res_capi' },
  { value: 'var_amort' },
  { value: 'redemption' },
  { value: 'indexed_nominal' },
  { value: 'indexed_revenue' },
  { value: 'buy' },
  { value: 'sell' },
  { value: 'cash' },
  // for bonds
  { value: 'cf_date', label: 'date de cash-flows', format: 'date' },
  { value: 'next_coupon_date', label: 'date du prochain coupon', format: 'date' },
  { value: 'last_coupon_date', label: 'date du dernier coupon', format: 'date' },
  { value: 't', label: 'temps (t)' },
  // for Property
  { value: 'amort' },
  { value: 'rent' },
  { value: 'charges' },
  // for equity
  //{ value: 'dividend', label: 'Dividendes' },
]

//const devise = { value: 'euro', symbol: '€' }
/* const devises = [
  { value: 'euro', symbol: '€' },
  { value: 'USD', symbol: '$' },
] */

const asset_field_map = {
  'Code valeur': 'code_val',
  catégorie: 'cat',
  Portefeuille: 'section',
  Libellé: 'label',
  'Valeur nette comptable': 'book_value',
  'Valeur de marché': 'market_value',
  'Plus/Moins value-latente': 'pmvl',
  'Taux nominal': 'nominal_yield',
  'date de remboursement': 'redemption_date',
  'Type de remboursement': 'redemption_mode',
  Notation: 'rating',
  Fréquence: 'freqs',
  'prix de revient': 'cost_price',
  'surote/décote': 'premium',
  Quantité: 'quantity',
  Cours: 'quote',
  'Nominal unit': 'nominal',
  'Remboursement unit': 'redemption',
  "Date d'émission": 'issue_date',
}

// liste des catégories d'actif
const actifs = [
  'fixed_rate_bond',
  'float_rate_bond',
  'infl_index_bond',
  'note',
  'equity',
  'property',
  'ucits',
  'fixed_rate_loan',
  'float_rate_loan',
  'ucits',
]

const classesActif = ['bond', 'equity', 'property', 'loan']

const cat_classe = {
  fixed_rate_bond: 'bond',
  float_rate_bond: 'bond',
  infl_index_bond: 'bond',
  note: 'bond',
  equity: 'equity',
  property: 'property',
  ucits: 'ucits',
  fixed_rate_loan: 'loan',
  float_rate_loan: 'loan',
}

const currencies = [
  { value: 'EUR', name: 'EUR - Euro' },
  { value: 'GBP', name: 'GBP - Livre britannique' },
  { value: 'USD', name: 'USD - Dollar américain' },
  { value: 'CAD', name: 'CAD - Dollar canadien' },
  { value: 'HKD', name: 'HKD - Dollar de Hong Kong' },
  { value: 'SGD', name: 'SGD - Dollar de Singapour' },
  { value: 'JPY', name: 'JPY - Yen japonais' },
  { value: 'CNY', name: 'CNY - Renminbi (Yuan) chinois' },
  { value: 'INR', name: 'INR - Roupie indien' },
  { value: 'VND', name: 'VND - Vietnam dong' },
  { value: 'Others', name: 'Autre' },
]

const ratings = [
  { value: 'AAA', name: 'AAA' },
  { value: 'AA', name: 'AA' },
  { value: 'A', name: 'A' },
  { value: 'BBB', name: 'BBB' },
  { value: 'BB', name: 'BB' },
  { value: 'B', name: 'B' },
  { value: 'CCC', name: 'CCC' },
  { value: 'CC', name: 'CC' },
  { value: 'C', name: 'C' },
  { value: 'HY', name: 'Hight Yield (HY)' },
  { value: 'NR', name: 'No Rating (NR)' },
]

const redemption_modes = [
  { value: 'in_fine', name: 'In fine' },
  { value: 'sea', name: 'SEA' },
]

const freqs = [
  { value: 1, name: 'annuel' },
  { value: 2, name: 'semestriel' },
  { value: 4, name: 'trimestriel' },
]

const rate_types = [
  { value: 'CMS', name: 'CMS' },
  { value: 'TME', name: 'TME' },
  { value: 'TEC', name: 'TEC' },
  { value: 'tx_court', name: 'Taux court' },
]

const bond_types = [
  { value: 'corporate', name: 'Entreprise', name_en: 'Corporate' },
  { value: 'government', name: 'Etat', name_en: 'Government' },
]

const loan_types = [
  { value: 'covered', name: 'Couvert', name_en: 'Covered' },
  { value: 'uncovered', name: 'Non couvert', name_en: 'Uncovered' },
]

const eq_types = [
  { value: 'listed', name: 'cotées' },
  { value: 'unlisted', name: 'cotées 2' },
  { value: 'participation', name: 'participations' },
]

const prop_types = [
  { value: '1', name: 'Placement 1' },
  { value: '2', name: 'Placement 2' },
  { value: '3', name: 'Placement 3' },
]
const ucits_types = [{ value: 'ucits', name: 'OPCVM' }]

const day_counts = [
  { value: 365, name: 'exact/365' },
  { value: 360, name: 'exact/360' },
]

// cat S2

const cat_s2_spds = [
  { value: '1', name: '1 Emprunt secteur privé' },
  { value: '2', name: '2 Obligations sécurisées' },
  { value: '3', name: '3 Emprunts Etats EEA' },
  { value: '4', name: '4 Emprunts Etats non EEA' },
  { value: '5', name: '5 Entreprises Assurances (exigence MCR OK)' },
  { value: '6', name: '6 Autres entreprises assurances' },
  { value: '7', name: '7 Avec sureté et sans exigence au tiers (0,75)' },
  { value: '8', name: '8 Avec sureté et avec exigence au tiers (0,85)' },
  { value: '11', name: '11 Titrisation type 1' },
  { value: '12', name: '12 Titrisation type 2' },
  { value: '13', name: 'Retitrisation' },
]

const cat_s2_eqs = [
  { value: '1', name: '1 Actions cotées type 1 EEA/OCDE (sauf participation)' },
  { value: '2', name: '2 Autres actions type 2 (non cotées ou hors EEA/OCDE' },
  { value: '3', name: '3 Participations dans institutions financières réglementées' },
  { value: '4', name: '4 Autres participations stratégiques type 1 (EEA/OCDE)' },
  { value: '5', name: '5 Autres participations stratégiques type 2' },
  { value: '6', name: '6 Autres type 1 et 2' },
  { value: '7', name: '7 Actions type 1 détention long terme' },
  { value: '8', name: '8 Actions type 2 détention long terme' },
  { value: '9', name: "9 Actions d'infrastructure" },
  { value: '10', name: "10 Actions d'infrastructure stratégiques" },
  { value: '11', name: "11 Actions d'infrastructure détention long terme" },
  { value: '12', name: "12 Actions de sociétés d'infrastructure" },
  { value: '13', name: "13 Actions de sociétés d'infrastructure stratégiques" },
  { value: '14', name: "14 Actions de sociétés d'infrastructure détention long terme" },
]

const cat_s2_props = [
  { value: '1', name: '1 Immobilier direct et sociétés immobilières' },
  { value: '2', name: '2 Sociétés de gestion / promotion immobilière' },
  { value: '3', name: '3 Sociétés avec endettement externe' },
]

// concentration
const cat_s2_cons = [
  {
    value: '0',
    name: 'Titres non soumis au choc et non dans la base asset',
    comment: 'titres intragroupe, UC, cash',
  },
  {
    value: '1',
    name: 'Titres soumis aux paramètres standards',
    comment: '[tableau des actes délégués - article 186] associés aux actifs Equity/ Corporate',
  },
  {
    value: '1C',
    name: "Titres soumis aux paramètres standards '1C' si covered",
    comment: 'obligations sécurisées',
  },
  {
    value: '2',
    name: 'Titres Etat non EEA',
    comment: '[tableau des actes délégués - article 187]',
  },
  { value: '2C', name: "Titres Etat non EEA '2C' si covered", comment: 'obligations sécurisées' },
  { value: '3', name: 'Titres de type Property', comment: '[choc immobilier spécifique]' },
  {
    value: '4',
    name: 'Titres non choqués mais présents dans la base Asset',
    comment: 'Etat EEA et OPC',
  },
]
// to be modified
const con_types = [
  { value: '1', name: '1' },
  { value: '2', name: '2' },
  { value: '3', name: '3' },
]

const fields = [
  { label: 'Catégorie', value: 'cat', column: 'B', number: 2, type: 'choice' },
  { label: 'Code valeur', value: 'code_val', column: 'E', number: 5 },
  { label: 'Section', value: 'section', column: 'C', number: 3 },
  { label: 'Libellé', value: 'label', column: 'F', number: 6, optional: true },
  { label: 'Emetteur', value: 'issuer', column: 'G', number: 7, optional: true },
  {
    label: 'Devise',
    value: 'currency',
    column: 'H',
    number: 8,
    optional: true,
    type: 'choice',
  },
  {
    label: 'Blocage vente',
    value: 'hold',
    column: 'AP',
    number: 42,
    optional: true,
    type: 'boolean',
  },
  {
    label: 'Date fin de blocage',
    value: 'hold_end_date',
    column: 'AQ',
    number: 43,
    optional: true,
    type: 'date',
  },
  { label: 'Quatité', value: 'quantity', column: 'M', number: 13, type: 'number' },
  { label: 'Prix de revient', value: 'cost_price', column: 'N', number: 14, type: 'number' },
  {
    label: 'Cat S2 spread',
    value: 'cat_s2_spd',
    column: 'AS',
    number: 45,
    cat: ['OTF', 'OTV', 'OII', 'TCNC', 'TCNA', 'PRT', 'PRTV', 'OPC'],
    type: 'choice',
  },
  {
    label: 'Cat S2 action',
    value: 'cat_s2_eq',
    column: 'AR',
    number: 44,
    cat: ['ACT', 'OPC'],
    type: 'choice',
  },
  {
    label: 'Cat S2 immo',
    value: 'cat_s2_prop',
    column: 'AT',
    number: 46,
    cat: ['IMMO', 'OPC'],
    type: 'choice',
  },
  {
    label: "Date d'émission",
    value: 'issue_date',
    column: 'T',
    number: 20,
    cat: ['OTF', 'OTV', 'OII', 'TCNC', 'TCNA', 'PRT', 'PRTV'],
    optional: true,
    type: 'date',
  },
  {
    label: "Date d'échéance",
    value: 'redemption_date',
    column: 'U',
    number: 21,
    cat: ['OTF', 'OTV', 'OII', 'TCNC', 'TCNA', 'PRT', 'PRTV'],
    type: 'date',
  },
  {
    label: 'Fréquence de paiement',
    value: 'freq',
    column: 'AI',
    number: 35,
    cat: ['OTF', 'OTV', 'OII', 'TCNC', 'TCNA', 'PRT', 'PRTV'],
    type: 'choice',
  },
  {
    label: 'Cours',
    value: 'quote',
    column: 'Q',
    number: 17,
    cat: ['OTF', 'OTV', 'OII', 'TCNC', 'TCNA', 'PRT', 'PRTV', 'ACT', 'OPC'],
    type: 'number',
  },
  {
    label: 'Surcote/décote',
    value: 'premium',
    column: 'O',
    number: 15,
    cat: ['OTF', 'OTV', 'OII', 'TCNC', 'TCNA', 'PRT', 'PRTV'],
    type: 'number',
  },
  {
    label: 'Taux nominal',
    value: 'nominal_yield',
    column: 'Z',
    number: 2,
    cat: ['OTF', 'OII', 'TCNC', 'TCNA', 'PRT'],
    type: 'number',
  },
  {
    label: "Nominal unitaire (à l'émission pour OTV)",
    value: 'nominal',
    column: 'W',
    number: 23,
    cat: ['OTF', 'OTV', 'OII', 'TCNC', 'TCNA'],
    type: 'number',
  },
  {
    label: 'Nominal unitaire indexé au 31/12 pour OTV',
    value: 'nominal_index_si',
    column: 'Y',
    number: 25,
    cat: ['OII'],
    type: 'number',
  },
  {
    label: 'Remboursement (unitaire)',
    value: 'redemption',
    column: 'X',
    number: 24,
    cat: ['OTF', 'TCNC'],
    type: 'number',
  },
  {
    label: 'Notation',
    value: 'rating',
    column: 'J',
    number: 10,
    cat: ['OTF', 'OTV', 'OII', 'TCNC', 'TCNA', 'PRT', 'PRTV'],
    type: 'choice',
  },
  {
    label: 'Année du 1er remboursement (si SEA)',
    value: 'start_redemp_date',
    column: 'S',
    number: 19,
    cat: ['OTF'],
    optional: true,
    type: 'date',
  },
  {
    label: "Valeur d'estimation",
    value: 'market_value',
    column: 'Q',
    number: 17,
    cat: ['PRT', 'PRTV', 'IMMO'],
    type: 'number',
  },
  {
    label: 'Type taux (OTV)',
    value: 'rate_type',
    column: 'AA',
    number: 27,
    cat: ['OTV'],
    type: 'choice',
  },
  {
    label: 'Type prêt',
    value: 'loan_type',
    column: 'S',
    number: 19,
    cat: ['PRT', 'PRTV'],
    type: 'choice',
  },
  {
    label: 'Durée ref (OTV)',
    value: 'ref_duration',
    column: 'AB',
    number: 28,
    cat: ['OTV'],
    type: 'number',
  },
  {
    label: 'Marge sur coupon (OTV)',
    value: 'coupon_margin',
    column: 'AC',
    number: 29,
    cat: ['OTV'],
    type: 'number',
  },
  {
    label: 'Plancher taux (OTV)',
    value: 'rate_floor',
    column: 'AE',
    number: 31,
    cat: ['OTV'],
    type: 'number',
  },
  {
    label: 'Plafond taux (OTV)',
    value: 'rate_cap',
    column: 'AF',
    number: 32,
    cat: ['OTV'],
    type: 'number',
  },
  {
    label: "Coupon couru à l'achat",
    value: 'running_coupon_0',
    column: 'AH',
    number: 34,
    cat: ['OTV'],
    type: 'number',
  },
  {
    label: 'Type action',
    value: 'eq_type',
    column: 'K',
    number: 11,
    cat: ['ACT'],
    type: 'choice',
  },
  {
    label: 'Type OPCVM',
    value: 'opc_type',
    column: 'L',
    number: 12,
    cat: ['OPC'],
    type: 'choice',
  },
  {
    label: 'Beta action',
    value: 'beta',
    column: 'T',
    number: 20,
    cat: ['ACT', 'OPC'],
    type: 'number',
  },
  {
    label: '% action',
    value: 'percent_eq',
    column: 'S',
    number: 19,
    cat: ['OPC'],
    type: 'number',
  },

  {
    label: '% monétaire',
    value: 'percent_mon',
    column: 'U',
    number: 21,
    cat: ['OPC'],
    type: 'number',
  },
  {
    label: 'Durée de référence monétaire (mois)',
    value: 'mon_ref_dur',
    column: 'V',
    number: 22,
    cat: ['OPC'],
    type: 'number',
  },
  {
    label: 'Notation monétaire',
    value: 'mon_rating',
    column: 'W',
    number: 23,
    cat: ['OPC'],
    type: 'choice',
  },

  {
    label: '% obligataire',
    value: 'percent_bond',
    column: 'X',
    number: 24,
    cat: ['OPC'],
    type: 'number',
  },
  {
    label: 'Durée de référence obligataire (années)',
    value: 'bond_ref_dur',
    column: 'Y',
    number: 25,
    cat: ['OPC'],
    type: 'number',
  },
  {
    label: 'Notation obligataire',
    value: 'mon_rating',
    column: 'Z',
    number: 26,
    cat: ['OPC'],
    type: 'choice',
  },

  {
    label: '% immobilier',
    value: 'percent_prop',
    column: 'AB',
    number: 28,
    cat: ['OPC'],
    type: 'number',
  },
  {
    label: 'Type immobilier',
    value: 'prop_type',
    column: 'AC',
    number: 29,
    cat: ['OPC'],
    type: 'choice',
  },
  {
    label: 'Taux de rentabilité immobilière',
    value: 'prop_return',
    column: 'AD',
    number: 30,
    cat: ['OPC'],
    type: 'number',
  },
  {
    label: 'Beta immobilière',
    value: 'prop_beta',
    column: 'AE',
    number: 31,
    cat: ['OPC'],
    type: 'number',
  },
]

const asset_para = {
  general: [
    { label: 'catégorie', value: 'cat', type: 'choice', choices: actifs },
    { label: 'section', value: 'section', type: 'choice', choices: [] }, // choices depend des sections definies dans parametres generaux. A fournir plus tard
    { label: 'libellé', value: 'label', placeholder: 'facultatif', type: 'text' },
    {
      label: 'code valeur',
      value: 'code_val',
      placeholder: 'requis',
      type: 'text',
      required: true,
    },
    { label: 'devise', value: 'currency', type: 'choice', choices: currencies },
    { label: "bloqué à la vente jusqu'au", value: 'hold_to', type: 'date' },
  ],
  concentration: [
    { label: 'cat S2 concentration', value: 'cat_s2_con', type: 'choice', choices: cat_s2_cons },
    { label: 'type de concentration', value: 'type_con', type: 'choice', choices: con_types },
    { label: 'groupe émetteur', value: 'issuer', type: 'text' },
  ],
  fixed_rate_bond: {
    init_values: [
      {
        label: 'prix de revient',
        value: 'cost_price',
        placeholder: 'total',
        type: 'number',
      },
      {
        label: 'surcote décote',
        value: 'premium',
        placeholder: 'surcote (<0) / décote (>0)',
        type: 'number',
        //symbol: devise.symbol,
      },
      { label: 'quantité', value: 'quantity', type: 'number' },
      {
        label: 'cours',
        value: 'quote',
        placeholder: 'VM pour 100 de nominal',
        type: 'number',
      },
      {
        label: 'Valeur nette comptable (VNC)',
        value: 'book_value',
        placeholder: 'calculée automatiquement',
        type: 'number',
        disabled: true,
      },
      {
        label: 'Valeur de marché (VM)',
        value: 'market_value',
        placeholder: 'calculée automatiquement',
        type: 'number',
        disabled: true,
      },
      {
        label: 'Plus/moins value latente (PMVL)',
        value: 'unrealized_pnl',
        placeholder: 'calculée automatiquement',
        type: 'number',
        disabled: true,
      },
    ],
    caract: [
      {
        label: 'Sous catégorie',
        value: 'sub_cat',
        type: 'choice',
        choices: bond_types,
      },
      {
        label: 'nominal',
        value: 'nominal',
        placehorlder: 'unitaire',
        //symbol: devise.symbol,
        type: 'number',
      },
      {
        label: 'remboursement',
        value: 'redemption',
        placehorlder: 'unitaire',
        //symbol: devise.symbol,
        type: 'number',
      },
      { label: "date d'émission", value: 'issue_date', type: 'date' },
      { label: 'date de remboursement', value: 'redemption_date', type: 'date', required: true },
      { label: 'taux nominal', value: 'nominal_yield', type: 'number', symbol: '%' },
      {
        label: 'type de remboursement',
        value: 'redemption_mode',
        type: 'choice',
        choices: redemption_modes,
      },
      {
        label: '1ère année remboursement SEA',
        value: 'start_redemp_date',
        type: 'number',
        placeholder: 'format yyyy, si rbt SEA',
      },
      { label: 'notation', value: 'rating', type: 'choice', choices: ratings },
      { label: 'taux de recouvrement', value: 'recovery_rate', type: 'number', symbol: '%' },
      { label: 'fréquence de coupon', value: 'freq', type: 'choice', choices: freqs },
    ],
    s2: [{ label: 'cat S2 spread', value: 'cat_s2_spd', type: 'choice', choices: cat_s2_spds }],
  },

  float_rate_bond: {
    init_values: [
      {
        label: 'prix de revient',
        value: 'cost_price',
        placeholder: 'total',
        type: 'number',
      },
      {
        label: 'surcote décote',
        value: 'premium',
        placeholder: 'surcote (<0) / décote (>0)',
        type: 'number',
        //symbol: devise.symbol,
      },
      { label: 'quantité', value: 'quantity', type: 'number' },
      {
        label: 'cours',
        value: 'quote',
        placeholder: 'VM pour 100 de nominal',
        type: 'number',
        symbol: '%',
      },
      {
        label: 'coupon couru',
        value: 'running_coupon',
        type: 'number',
      },
      {
        label: 'Valeur nette comptable (VNC)',
        value: 'book_value',
        placeholder: 'calculée automatiquement',
        type: 'number',
        disabled: true,
      },
      {
        label: 'Valeur de marché (VM)',
        value: 'market_value',
        placeholder: 'calculée automatiquement',
        type: 'number',
        disabled: true,
      },
      {
        label: 'Plus/moins value latente (PMVL)',
        value: 'unrealized_pnl',
        placeholder: 'calculée automatiquement',
        type: 'number',
        disabled: true,
      },
    ],
    caract: [
      {
        label: 'Sous catégorie',
        value: 'sub_cat',
        type: 'choice',
        choices: bond_types,
      },
      {
        label: 'nominal',
        value: 'nominal',
        placehorlder: 'unitaire',
        //symbol: devise.symbol,
        type: 'number',
      },
      { label: "date d'émission", value: 'issue_date', type: 'date' },
      { label: 'date de remboursement', value: 'redemption_date', type: 'date', required: true },
      { label: 'type de taux', value: 'rate_type', type: 'choice', choices: rate_types },
      { label: 'taux de référence', value: 'rate_ref', type: 'text', placeholder: 'facultatif' },
      {
        label: 'durée de référence',
        value: 'duration_ref',
        type: 'number',
        symbol: 'ans',
      },
      { label: 'marge faciale', value: 'facial_margin', type: 'number' },
      { label: 'plancher', value: 'floor', type: 'number' },
      { label: 'plafond', value: 'cap', type: 'number' },
      { label: 'notation', value: 'rating', type: 'choice', choices: ratings },
      { label: 'taux de recouvrement', value: 'recovery_rate', type: 'number' },
      { label: 'fréquence de coupon', value: 'freq', type: 'choice', choices: freqs },
    ],
    s2: [{ label: 'cat S2 spread', value: 'cat_s2_spd', type: 'choice', choices: cat_s2_spds }],
  },

  infl_index_bond: {
    init_values: [
      {
        label: 'Prix de revient',
        value: 'cost_price',
        placeholder: 'total',
        type: 'number',
      },
      {
        label: 'Surcote décote',
        value: 'premium',
        placeholder: 'surcote (<0) / décote (>0)',
        type: 'number',
        //symbol: devise.symbol,
      },
      { label: 'Quantité', value: 'quantity', type: 'number' },
      {
        label: 'Cours',
        value: 'quote',
        placeholder: 'VM pour 100 de nominal',
        type: 'number',
        symbol: '%',
      },
      {
        label: "Nominal global indexé à l'achat",
        value: 'buy_indexed_nominal',
        type: 'number',
        //symbol: devise.symbol,
      },
      {
        label: 'Valeur nette comptable (VNC)',
        value: 'book_value',
        placeholder: 'calculée automatiquement',
        type: 'number',
        disabled: true,
      },
      {
        label: 'Valeur de marché (VM)',
        value: 'market_value',
        placeholder: 'calculée automatiquement',
        type: 'number',
        disabled: true,
      },
      {
        label: 'Plus/moins value latente (PMVL)',
        value: 'unrealized_pnl',
        placeholder: 'calculée automatiquement',
        type: 'number',
        disabled: true,
      },
    ],
    caract: [
      {
        label: 'Sous catégorie',
        value: 'sub_cat',
        type: 'choice',
        choices: bond_types,
      },
      {
        label: "Nominal à l'émission",
        value: 'nominal',
        placehorlder: 'unitaire',
        //symbol: devise.symbol,
        type: 'number',
      },
      {
        label: 'Nominal indexé (31/12)',
        value: 'nominal_index_si',
        placehorlder: 'unitaire',
        //symbol: devise.symbol,
        type: 'number',
      },
      { label: "Date d'émission", value: 'issue_date', type: 'date' },
      { label: 'Date de remboursement', value: 'redemption_date', type: 'date', required: true },
      { label: 'Taux nominal', value: 'nominal_yield', type: 'number' },
      { label: 'Notation', value: 'rating', type: 'choice', choices: ratings },
      { label: 'Taux de recouvrement', value: 'recovery_rate', type: 'number' },
      { label: 'fréquence de coupon', value: 'freq', type: 'choice', choices: freqs },
    ],
    s2: [{ label: 'Cat S2 spread', value: 'cat_s2_spd', type: 'choice', choices: cat_s2_spds }],
  },

  note: {
    init_values: [
      {
        label: 'Prix de revient',
        value: 'cost_price',
        placeholder: 'total',
        type: 'number',
      },
      {
        label: 'Coupon couru + décote',
        value: 'premium',
        type: 'number',
        //symbol: devise.symbol,
      },
      { label: 'Quantité', value: 'quantity', type: 'const', const: 1 },
      {
        label: 'Valeur de marché',
        value: 'market_value',
        type: 'number',
        //symbol: devise.symbol,
      },
    ],
    caract: [
      {
        label: 'Sous catégorie',
        value: 'sub_cat',
        type: 'choice',
        choices: bond_types,
      },
      {
        label: 'Nominal',
        value: 'nominal',
        //symbol: devise.symbol,
        type: 'number',
      },
      { label: "Date d'émission", value: 'issue_date', type: 'date' },
      { label: 'Date de remboursement', value: 'redemption_date', type: 'date', required: true },
      { label: "Taux d'escompte", value: 'discount_rate', type: 'number' },
      { label: 'Notation', value: 'rating', type: 'choice', choices: ratings },
      { label: 'Taux de recouvrement', value: 'recovery_rate', type: 'number' },
    ],
    s2: [{ label: 'Cat S2 spread', value: 'cat_s2_spd', type: 'choice', choices: cat_s2_spds }],
  },

  fixed_rate_loan: {
    init_values: [
      {
        label: 'prix de revient',
        value: 'cost_price',
        placeholder: 'total',
        type: 'number',
      },
      {
        label: 'surcote décote',
        value: 'premium',
        placeholder: 'surcote (<0) / décote (>0)',
        type: 'number',
        //symbol: devise.symbol,
      },
      { label: 'quantité', value: 'quantity', type: 'number' },
      {
        label: 'cours',
        value: 'quote',
        placeholder: 'VM pour 100 de nominal',
        type: 'number',
      },
      {
        label: 'Valeur nette comptable (VNC)',
        value: 'book_value',
        placeholder: 'calculée automatiquement',
        type: 'number',
        disabled: true,
      },
      {
        label: 'Valeur de marché (VM)',
        value: 'market_value',
        placeholder: 'calculée automatiquement',
        type: 'number',
        disabled: true,
      },
      {
        label: 'Plus/moins value latente (PMVL)',
        value: 'unrealized_pnl',
        placeholder: 'calculée automatiquement',
        type: 'number',
        disabled: true,
      },
    ],
    caract: [
      {
        label: 'nominal',
        value: 'nominal',
        placehorlder: 'unitaire',
        //symbol: devise.symbol,
        type: 'number',
      },
      {
        label: 'remboursement',
        value: 'redemption',
        placehorlder: 'unitaire',
        //symbol: devise.symbol,
        type: 'number',
      },
      { label: "date d'émission", value: 'issue_date', type: 'date' },
      { label: 'date de remboursement', value: 'redemption_date', type: 'date', required: true },
      { label: 'taux nominal', value: 'nominal_yield', type: 'number', symbol: '%' },
      {
        label: 'type de remboursement',
        value: 'redemption_mode',
        type: 'choice',
        choices: redemption_modes,
      },
      {
        label: '1er remboursement SEA',
        value: 'start_redemp_date',
        type: 'number',
        placeholder: 'année au format yyyy',
      },
      { label: 'notation', value: 'rating', type: 'choice', choices: ratings },
      { label: 'taux de recouvrement', value: 'recovery_rate', type: 'number' },
      { label: 'fréquence de paiement', value: 'freq', type: 'choice', choices: freqs },
    ],
    s2: [{ label: 'cat S2 spread', value: 'cat_s2_spd', type: 'choice', choices: cat_s2_spds }],
  },
  float_rate_loan: {
    init_values: [
      {
        label: 'prix de revient',
        value: 'cost_price',
        placeholder: 'total',
        type: 'number',
      },
      {
        label: 'surcote décote',
        value: 'premium',
        placeholder: 'surcote (<0) / décote (>0)',
        type: 'number',
        //symbol: devise.symbol,
      },
      { label: 'quantité', value: 'quantity', type: 'number' },
      {
        label: 'cours',
        value: 'quote',
        placeholder: 'VM pour 100 de nominal',
        type: 'number',
      },
      {
        label: 'Valeur nette comptable (VNC)',
        value: 'book_value',
        placeholder: 'calculée automatiquement',
        type: 'number',
        disabled: true,
      },
      {
        label: 'Valeur de marché (VM)',
        value: 'market_value',
        placeholder: 'calculée automatiquement',
        type: 'number',
        disabled: true,
      },
      {
        label: 'Plus/moins value latente (PMVL)',
        value: 'unrealized_pnl',
        placeholder: 'calculée automatiquement',
        type: 'number',
        disabled: true,
      },
    ],
    caract: [
      {
        label: 'nominal',
        value: 'nominal',
        placehorlder: 'unitaire',
        //symbol: devise.symbol,
        type: 'number',
      },
      {
        label: 'remboursement',
        value: 'redemption',
        placehorlder: 'unitaire',
        //symbol: devise.symbol,
        type: 'number',
      },
      { label: "date d'émission", value: 'issue_date', type: 'date' },
      { label: 'date de remboursement', value: 'redemption_date', type: 'date', required: true },
      { label: 'taux nominal', value: 'nominal_yield', type: 'number', symbol: '%' },
      {
        label: 'type de remboursement',
        value: 'redemption_mode',
        type: 'choice',
        choices: redemption_modes,
      },
      {
        label: '1er remboursement SEA',
        value: 'start_redemp_date',
        type: 'number',
        placeholder: 'année au format yyyy',
      },
      { label: 'notation', value: 'rating', type: 'choice', choices: ratings },
      { label: 'taux de recouvrement', value: 'recovery_rate', type: 'number' },
      { label: 'fréquence de paiement', value: 'freq', type: 'choice', choices: freqs },
    ],
    s2: [{ label: 'cat S2 spread', value: 'cat_s2_spd', type: 'choice', choices: cat_s2_spds }],
  },

  equity: {
    init_values: [
      {
        label: 'Prix de revient',
        value: 'cost_price',
        placeholder: 'total',
        type: 'number',
      },
      {
        label: 'Provision durable',
        value: 'pdd_init',
        type: 'number',
        //symbol: devise.symbol,
      },
      { label: 'Quantité', value: 'quantity', type: 'number' },
      {
        label: 'Cours',
        value: 'quote',
        placeholder: 'VM pour 100 de nominal',
        type: 'number',
      },
      {
        label: 'Bêta',
        value: 'beta',
        placeholder: 1,
        type: 'number',
      },
      {
        label: 'Valeur nette comptable (VNC)',
        value: 'book_value',
        placeholder: 'calculée automatiquement',
        type: 'number',
        disabled: true,
      },
      {
        label: 'Valeur de marché (VM)',
        value: 'market_value',
        placeholder: 'calculée automatiquement',
        type: 'number',
        disabled: true,
      },
      {
        label: 'Plus/moins value latente (PMVL)',
        value: 'unrealized_pnl',
        placeholder: 'calculée automatiquement',
        type: 'number',
        disabled: true,
      },
    ],
    caract: [
      {
        label: 'Sous catégorie',
        value: 'sub_cat',
        type: 'choice',
        choices: eq_types,
      },
    ],
    s2: [{ label: 'Cat S2 action', value: 'cat_s2_eq', type: 'choice', choices: cat_s2_eqs }],
  },

  property: {
    init_values: [
      /*       {
        label: 'Prix de revient',
        value: 'cost_price',
        placeholder: 'total',
        type: 'number',
      }, 
      { label: 'Quantité', value: 'quantity', type: 'number' }, */
      {
        label: 'Valeur de marché',
        value: 'quote',
        placeholder: 'estimée',
        type: 'number',
      },
      {
        label: 'Valeur nette comptable (VNC)',
        value: 'book_value',
        placeholder: 'calculée automatiquement',
        type: 'number',
        disabled: true,
      },

      {
        label: 'Plus/moins value latente (PMVL)',
        value: 'unrealized_pnl',
        placeholder: 'calculée automatiquement',
        type: 'number',
        disabled: true,
      },
      {
        label: 'Provision durable',
        value: 'pdd_init',
        type: 'number',
        //symbol: devise.symbol,
      },
      {
        label: "Frais d'acquisition déjà amorti",
        value: 'amort_acqui_cost_init',
        placeholder: '',
        type: 'number',
      },
      {
        label: 'Valeur de construction déjà amortie',
        value: 'amort_constr_init',
        placeholder: '',
        type: 'number',
      },
      {
        label: "Durée d'amortissement restante pour frais d'acquisition",
        value: 'amort_acqui_cost_months',
        placeholder: '',
        type: 'number',
        symbol: 'mois',
      },
      {
        label: "Durée d'amortissement restante pour valeur de construction",
        value: 'amort_constr_months',
        placeholder: '',
        type: 'number',
        symbol: 'mois',
      },
      {
        label: 'Loyer mensuel',
        value: 'rent',
        placeholder: '',
        type: 'number',
      },
      {
        label: 'Charges mensuelles',
        value: 'charges',
        placeholder: '',
        type: 'number',
      },
    ],
    caract: [
      {
        label: 'Sous catégorie',
        value: 'sub_cat',
        type: 'choice',
        choices: prop_types,
      },
      {
        label: "Frais d'acquisition",
        value: 'acqui_cost',
        placeholder: '',
        type: 'number',
      },
      {
        label: 'Construction (totale)',
        value: 'constr',
        placeholder: '',
        type: 'number',
      },
      {
        label: 'Terrain (total)',
        value: 'land',
        placeholder: '',
        type: 'number',
      },
      {
        label: 'Prix de revient',
        value: 'cost_price',
        placeholder: '',
        type: 'number',
        disabled: true,
      },
    ],
    s2: [
      { label: 'Cat S2 immobilier', value: 'cat_s2_prop', type: 'choice', choices: cat_s2_props },
    ],
  },

  ucits: {
    init_values: [
      {
        label: 'Prix de revient',
        value: 'cost_price',
        placeholder: 'total',
        type: 'number',
      },
      {
        label: 'Provision durable',
        value: 'pdd',
        type: 'number',
        //symbol: devise.symbol,
      },
      { label: 'Quantité', value: 'quantity', type: 'number' },
      {
        label: 'Cours',
        value: 'quote',
        placeholder: 'VM pour 100 de nominal',
        type: 'number',
      },
    ],
    caract: [
      {
        label: 'Type',
        value: 'eq_type',
        type: 'choice',
        choices: eq_types,
      },
    ],
    s2: [{ label: 'Cat S2 action', value: 'cat_s2_eq', type: 'choice', choices: cat_s2_eqs }],
  },
}

//const baseURL = 'http://localhost:8000/ptf'

export {
  asset_para,
  //devises,
  //devise,
  actifs,
  classesActif,
  cat_classe,
  ratings,
  redemption_modes,
  freqs,
  //err0,
  //inputs0,
  //inputs0_per_cat,
  //err0_fixed_rate_bond,
  asset_field_map,
  fields,
  currencies,
  bond_types,
  loan_types,
  eq_types,
  rate_types,
  prop_types,
  ucits_types,
  day_counts,
  cat_s2_eqs,
  cat_s2_spds,
  cat_s2_props,
  cat_s2_cons,
}
