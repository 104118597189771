import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  nonEco: {
    loadLink:
      'https://docs.google.com/spreadsheets/d/e/2PACX-1vRTowYFb526QkP4-3rbUGPj3km29pOIo5mCO6kBwe81F2hjwZYeaJAEpuekYwt2kBdCoPYLvqnVzk7X/pub?output=xlsx',
    viewLink:
      'https://docs.google.com/spreadsheets/d/1x-Us4lgCIc4AOqvPVc1KUvQMBfI_cRuxguGHrLcD8Rk/edit?usp=sharing',
    loaded: false,
  },
  eco: {
    loadLink:
      'https://docs.google.com/spreadsheets/d/e/2PACX-1vQPKjdvBJsqT_6oYq-U41RQnq1YgzSIohp6V1r8vBveTvnEtoXdiaPqM-Xa0uoDziedacKXN1Qma9S9/pub?output=xlsx',
    viewLink:
      'https://docs.google.com/spreadsheets/d/1KxTbLC2iydVMFt6F4l3HDCuO0IFW3EZPAbbECpVds20/edit?usp=sharing',
    loaded: false,
  },
}
// model point list
export const workSpaceSlice = createSlice({
  name: 'workSpace',
  initialState,
  reducers: {
    load: (state, action) => {
      const inputs = action.payload
      state[inputs.id].loadLink = inputs.loadLink
      state[inputs.id].loaded = true
    },

    setViewLink: (state, action) => {
      state[action.payload.id].viewLink = action.payload.value
    },
  },
})

export const { supprimer, dupliquer, modifier, ajouter, load, setViewLink } = workSpaceSlice.actions
export const getWorkSpace = (state) => state.workSpace

export default workSpaceSlice.reducer
