import { createSlice } from '@reduxjs/toolkit'
import { supprimer, dupliquer, modifier, ajouter, loadAsset } from '../actifSlice'

const initialState = []
// model point list
export const sensiAssetSlice = createSlice({
  name: 'sensiAssets',
  initialState,
  reducers: {
    load: (state, action) => (state = action.payload),
  },

  extraReducers: (builder) => {
    builder.addCase(loadAsset, (state, action) => {
      return state
    })
  },
})

export const { load } = sensiAssetSlice.actions
export const getSensiAssets = (state) => state.sensiAssets

export default sensiAssetSlice.reducer
