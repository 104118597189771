import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  fileName: null,
  pageNumber: 1,
  fullMode: false,
  textMode: false,
  size: 1,
}

export const pdfSlice = createSlice({
  name: 'pdf',
  initialState,
  reducers: {
    setFileName: (state, action) => (state = { ...state, fileName: action.payload }),
    nextPage: (state) => (state = { ...state, pageNumber: state.pageNumber + 1 }),
    prevPage: (state) => (state = { ...state, pageNumber: Math.max(1, state.pageNumber - 1) }),
    setPage: (state, action) => (state = { ...state, pageNumber: parseInt(action.payload) }),
    zoomIn: (state) => (state = { ...state, size: state.size + 0.1 }),
    zoomOut: (state) => (state = { ...state, size: Math.max(0, state.size - 0.1) }),
    setZoom: (state, action) => (state = { ...state, size: parseFloat(action.payload) }),
    setFullMode: (state, action) => (state = { ...state, fullMode: action.payload }),
    setTextMode: (state, action) => (state = { ...state, textMode: action.payload }),
  },
})

export const {
  setFileName,
  setFileAndPage,
  nextPage,
  prevPage,
  setPage,
  zoomIn,
  zoomOut,
  setZoom,
  setFullMode,
  setTextMode,
} = pdfSlice.actions
export const getPdf = (state) => state.pdf

export default pdfSlice.reducer
