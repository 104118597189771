export const dataDemo = {
  name: 'exemple',
  alpha: 0.1155,
  ufr: 0.033,
  dim: 60,
  data: [
    {
      date: '31/12/2023',
      short_term_rate: 0.03964,
      long_term_rate: 0.02603,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.06,
      stock_index: 100,
      dividend_yield: 0.03,
      property_index: 100,
      occup_rate: 1,
    },
    {
      date: '31/01/2024',
      long_term_rate: 0.0271,
      short_term_rate: 0.03,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.06,
      stock_index: 100.2,
      dividend_yield: 0.03,
      property_index: 100.4,
      occup_rate: 1,
    },
    {
      date: '29/02/2024',
      long_term_rate: 0.03,
      short_term_rate: 0.03,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.06,
      stock_index: 100.4004,
      dividend_yield: 0.03,
      property_index: 100.8016,
      occup_rate: 1,
    },
    {
      date: '31/03/2024',
      long_term_rate: 0.035,
      short_term_rate: 0.03,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.06,
      stock_index: 100.6012008,
      dividend_yield: 0.03,
      property_index: 101.2048064,
      occup_rate: 1,
    },
    {
      date: '30/04/2024',
      long_term_rate: 0.04,
      short_term_rate: 0.03,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.06,
      stock_index: 100.8024032016,
      dividend_yield: 0.03,
      property_index: 101.6096256256,
      occup_rate: 1,
    },
    {
      date: '31/05/2024',
      long_term_rate: 0.04,
      short_term_rate: 0.03,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.06,
      stock_index: 101.00400800800318,
      dividend_yield: 0.03,
      property_index: 102.0160641281024,
      occup_rate: 1,
    },
    {
      date: '30/06/2024',
      long_term_rate: 0.04,
      short_term_rate: 0.03,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.06,
      stock_index: 101.20601602401919,
      dividend_yield: 0.03,
      property_index: 102.4241283846148,
      occup_rate: 1,
    },
    {
      date: '31/07/2024',
      long_term_rate: 0.04,
      short_term_rate: 0.03,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.06,
      stock_index: 101.40842805606724,
      dividend_yield: 0.03,
      property_index: 102.83382489815325,
      occup_rate: 1,
    },
    {
      date: '31/08/2024',
      long_term_rate: 0.04,
      short_term_rate: 0.03,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.06,
      stock_index: 101.6112449121794,
      dividend_yield: 0.03,
      property_index: 103.24516019774588,
      occup_rate: 1,
    },
    {
      date: '30/09/2024',
      long_term_rate: 0.04,
      short_term_rate: 0.03,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.06,
      stock_index: 101.81446740200376,
      dividend_yield: 0.03,
      property_index: 103.65814083853687,
      occup_rate: 1,
    },
    {
      date: '31/10/2024',
      long_term_rate: 0.04,
      short_term_rate: 0.03,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.06,
      stock_index: 102.01809633680777,
      dividend_yield: 0.03,
      property_index: 104.07277340189101,
      occup_rate: 1,
    },
    {
      date: '30/11/2024',
      long_term_rate: 0.04,
      short_term_rate: 0.03,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.06,
      stock_index: 102.2221325294814,
      dividend_yield: 0.03,
      property_index: 104.4890644954986,
      occup_rate: 1,
    },
    {
      date: '31/12/2024',
      long_term_rate: 0.04,
      short_term_rate: 0.03,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.05,
      stock_index: 102.42657679454035,
      dividend_yield: 0.03,
      property_index: 104.90702075348058,
      occup_rate: 1,
    },
    {
      date: '31/01/2025',
      long_term_rate: 0.04,
      short_term_rate: 0.03,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.05,
      stock_index: 102.63142994812945,
      dividend_yield: 0.03,
      property_index: 105.32664883649451,
      occup_rate: 1,
    },
    {
      date: '28/02/2025',
      long_term_rate: 0.04,
      short_term_rate: 0.03,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.05,
      stock_index: 102.83669280802572,
      dividend_yield: 0.03,
      property_index: 105.7479554318405,
      occup_rate: 1,
    },
    {
      date: '31/03/2025',
      long_term_rate: 0.04,
      short_term_rate: 0.03,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.05,
      stock_index: 103.04236619364178,
      dividend_yield: 0.03,
      property_index: 106.17094725356786,
      occup_rate: 1,
    },
    {
      date: '30/04/2025',
      long_term_rate: 0.04,
      short_term_rate: 0.03,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.05,
      stock_index: 103.24845092602907,
      dividend_yield: 0.03,
      property_index: 106.59563104258214,
      occup_rate: 1,
    },
    {
      date: '31/05/2025',
      long_term_rate: 0.04,
      short_term_rate: 0.03,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.05,
      stock_index: 103.45494782788111,
      dividend_yield: 0.03,
      property_index: 107.02201356675248,
      occup_rate: 1,
    },
    {
      date: '30/06/2025',
      long_term_rate: 0.04,
      short_term_rate: 0.03,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.045,
      stock_index: 103.66185772353688,
      dividend_yield: 0.03,
      property_index: 107.45010162101948,
      occup_rate: 1,
    },
    {
      date: '31/07/2025',
      long_term_rate: 0.04,
      short_term_rate: 0.03,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.045,
      stock_index: 103.86918143898396,
      dividend_yield: 0.03,
      property_index: 107.87990202750356,
      occup_rate: 1,
    },
    {
      date: '31/08/2025',
      long_term_rate: 0.04,
      short_term_rate: 0.03,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.045,
      stock_index: 104.07691980186193,
      dividend_yield: 0.03,
      property_index: 108.31142163561357,
      occup_rate: 1,
    },
    {
      date: '30/09/2025',
      long_term_rate: 0.04,
      short_term_rate: 0.03,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.045,
      stock_index: 104.28507364146567,
      dividend_yield: 0.03,
      property_index: 108.74466732215602,
      occup_rate: 1,
    },
    {
      date: '31/10/2025',
      long_term_rate: 0.04,
      short_term_rate: 0.03,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.045,
      stock_index: 104.49364378874861,
      dividend_yield: 0.03,
      property_index: 109.17964599144467,
      occup_rate: 1,
    },
    {
      date: '30/11/2025',
      long_term_rate: 0.04,
      short_term_rate: 0.03,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.045,
      stock_index: 104.70263107632611,
      dividend_yield: 0.03,
      property_index: 109.61636457541046,
      occup_rate: 1,
    },
    {
      date: '31/12/2025',
      long_term_rate: 0.04,
      short_term_rate: 0.03,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.04,
      stock_index: 104.91203633847877,
      dividend_yield: 0.03,
      property_index: 110.05483003371211,
      occup_rate: 1,
    },
    {
      date: '31/01/2026',
      long_term_rate: 0.035,
      short_term_rate: 0.025,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.04,
      stock_index: 105.12186041115575,
      dividend_yield: 0.03,
      property_index: 110.49504935384695,
      occup_rate: 1,
    },
    {
      date: '28/02/2026',
      long_term_rate: 0.035,
      short_term_rate: 0.025,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.04,
      stock_index: 105.33210413197807,
      dividend_yield: 0.03,
      property_index: 110.93702955126236,
      occup_rate: 1,
    },
    {
      date: '31/03/2026',
      long_term_rate: 0.035,
      short_term_rate: 0.025,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.04,
      stock_index: 105.54276834024203,
      dividend_yield: 0.03,
      property_index: 111.38077766946742,
      occup_rate: 1,
    },
    {
      date: '30/04/2026',
      long_term_rate: 0.035,
      short_term_rate: 0.025,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.04,
      stock_index: 105.75385387692253,
      dividend_yield: 0.03,
      property_index: 111.8263007801453,
      occup_rate: 1,
    },
    {
      date: '31/05/2026',
      long_term_rate: 0.035,
      short_term_rate: 0.025,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.04,
      stock_index: 105.96536158467639,
      dividend_yield: 0.03,
      property_index: 112.27360598326587,
      occup_rate: 1,
    },
    {
      date: '30/06/2026',
      long_term_rate: 0.035,
      short_term_rate: 0.025,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.04,
      stock_index: 106.17729230784573,
      dividend_yield: 0.03,
      property_index: 112.72270040719893,
      occup_rate: 1,
    },
    {
      date: '31/07/2026',
      long_term_rate: 0.035,
      short_term_rate: 0.025,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.04,
      stock_index: 106.38964689246144,
      dividend_yield: 0.03,
      property_index: 113.17359120882772,
      occup_rate: 1,
    },
    {
      date: '31/08/2026',
      long_term_rate: 0.035,
      short_term_rate: 0.025,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.03,
      stock_index: 106.60242618624636,
      dividend_yield: 0.03,
      property_index: 113.62628557366303,
      occup_rate: 1,
    },
    {
      date: '30/09/2026',
      long_term_rate: 0.035,
      short_term_rate: 0.025,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.03,
      stock_index: 106.81563103861886,
      dividend_yield: 0.03,
      property_index: 114.08079071595768,
      occup_rate: 1,
    },
    {
      date: '31/10/2026',
      long_term_rate: 0.035,
      short_term_rate: 0.025,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.03,
      stock_index: 107.02926230069612,
      dividend_yield: 0.03,
      property_index: 114.53711387882151,
      occup_rate: 1,
    },
    {
      date: '30/11/2026',
      long_term_rate: 0.035,
      short_term_rate: 0.025,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.03,
      stock_index: 107.24332082529753,
      dividend_yield: 0.03,
      property_index: 114.99526233433681,
      occup_rate: 1,
    },
    {
      date: '31/12/2026',
      long_term_rate: 0.035,
      short_term_rate: 0.025,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.03,
      stock_index: 107.45780746694814,
      dividend_yield: 0.03,
      property_index: 115.45524338367417,
      occup_rate: 1,
    },
    {
      date: '31/01/2027',
      long_term_rate: 0.03,
      short_term_rate: 0.02,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.03,
      stock_index: 107.67272308188204,
      dividend_yield: 0.03,
      property_index: 115.91706435720887,
      occup_rate: 1,
    },
    {
      date: '28/02/2027',
      long_term_rate: 0.03,
      short_term_rate: 0.02,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.03,
      stock_index: 107.8880685280458,
      dividend_yield: 0.03,
      property_index: 116.38073261463772,
      occup_rate: 1,
    },
    {
      date: '31/03/2027',
      long_term_rate: 0.03,
      short_term_rate: 0.02,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.025,
      stock_index: 108.1038446651019,
      dividend_yield: 0.03,
      property_index: 116.84625554509627,
      occup_rate: 1,
    },
    {
      date: '30/04/2027',
      long_term_rate: 0.03,
      short_term_rate: 0.02,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.025,
      stock_index: 108.3200523544321,
      dividend_yield: 0.03,
      property_index: 117.31364056727665,
      occup_rate: 1,
    },
    {
      date: '31/05/2027',
      long_term_rate: 0.03,
      short_term_rate: 0.02,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.025,
      stock_index: 108.53669245914097,
      dividend_yield: 0.03,
      property_index: 117.78289512954578,
      occup_rate: 1,
    },
    {
      date: '30/06/2027',
      long_term_rate: 0.03,
      short_term_rate: 0.02,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.025,
      stock_index: 108.75376584405925,
      dividend_yield: 0.03,
      property_index: 118.25402671006398,
      occup_rate: 1,
    },
    {
      date: '31/07/2027',
      long_term_rate: 0.03,
      short_term_rate: 0.02,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.025,
      stock_index: 108.97127337574737,
      dividend_yield: 0.03,
      property_index: 118.72704281690424,
      occup_rate: 1,
    },
    {
      date: '31/08/2027',
      long_term_rate: 0.03,
      short_term_rate: 0.02,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.025,
      stock_index: 109.18921592249887,
      dividend_yield: 0.03,
      property_index: 119.20195098817186,
      occup_rate: 1,
    },
    {
      date: '30/09/2027',
      long_term_rate: 0.03,
      short_term_rate: 0.02,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.025,
      stock_index: 109.40759435434389,
      dividend_yield: 0.03,
      property_index: 119.67875879212455,
      occup_rate: 1,
    },
    {
      date: '31/10/2027',
      long_term_rate: 0.03,
      short_term_rate: 0.02,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.025,
      stock_index: 109.62640954305257,
      dividend_yield: 0.03,
      property_index: 120.15747382729307,
      occup_rate: 1,
    },
    {
      date: '30/11/2027',
      long_term_rate: 0.03,
      short_term_rate: 0.02,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.025,
      stock_index: 109.84566236213868,
      dividend_yield: 0.03,
      property_index: 120.63810372260225,
      occup_rate: 1,
    },
    {
      date: '31/12/2027',
      long_term_rate: 0.03,
      short_term_rate: 0.02,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.025,
      stock_index: 110.06535368686296,
      dividend_yield: 0.03,
      property_index: 121.12065613749267,
      occup_rate: 1,
    },
    {
      date: '31/01/2028',
      long_term_rate: 0.025,
      short_term_rate: 0.02,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.02,
      stock_index: 110.28548439423669,
      dividend_yield: 0.03,
      property_index: 121.60513876204266,
      occup_rate: 1,
    },
    {
      date: '29/02/2028',
      long_term_rate: 0.025,
      short_term_rate: 0.02,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.02,
      stock_index: 110.50605536302515,
      dividend_yield: 0.03,
      property_index: 122.09155931709084,
      occup_rate: 1,
    },
    {
      date: '31/03/2028',
      long_term_rate: 0.025,
      short_term_rate: 0.02,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.02,
      stock_index: 110.72706747375122,
      dividend_yield: 0.03,
      property_index: 122.5799255543592,
      occup_rate: 1,
    },
    {
      date: '30/04/2028',
      long_term_rate: 0.025,
      short_term_rate: 0.02,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.02,
      stock_index: 110.94852160869875,
      dividend_yield: 0.03,
      property_index: 123.07024525657664,
      occup_rate: 1,
    },
    {
      date: '31/05/2028',
      long_term_rate: 0.025,
      short_term_rate: 0.02,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.02,
      stock_index: 111.17041865191617,
      dividend_yield: 0.03,
      property_index: 123.56252623760295,
      occup_rate: 1,
    },
    {
      date: '30/06/2028',
      long_term_rate: 0.025,
      short_term_rate: 0.02,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.02,
      stock_index: 111.39275948922,
      dividend_yield: 0.03,
      property_index: 124.05677634255338,
      occup_rate: 1,
    },
    {
      date: '31/07/2028',
      long_term_rate: 0.025,
      short_term_rate: 0.02,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.02,
      stock_index: 111.61554500819844,
      dividend_yield: 0.03,
      property_index: 124.5530034479236,
      occup_rate: 1,
    },
    {
      date: '31/08/2028',
      long_term_rate: 0.025,
      short_term_rate: 0.02,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.02,
      stock_index: 111.83877609821484,
      dividend_yield: 0.03,
      property_index: 125.05121546171529,
      occup_rate: 1,
    },
    {
      date: '30/09/2028',
      long_term_rate: 0.025,
      short_term_rate: 0.02,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.02,
      stock_index: 112.06245365041129,
      dividend_yield: 0.03,
      property_index: 125.55142032356216,
      occup_rate: 1,
    },
    {
      date: '31/10/2028',
      long_term_rate: 0.025,
      short_term_rate: 0.02,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.02,
      stock_index: 112.2865785577121,
      dividend_yield: 0.03,
      property_index: 126.05362600485641,
      occup_rate: 1,
    },
    {
      date: '30/11/2028',
      long_term_rate: 0.025,
      short_term_rate: 0.02,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.02,
      stock_index: 112.51115171482753,
      dividend_yield: 0.03,
      property_index: 126.55784050887584,
      occup_rate: 1,
    },
    {
      date: '31/12/2028',
      long_term_rate: 0.025,
      short_term_rate: 0.02,
      spread_aaa: '',
      spread_aa: 0.005,
      spread_a: 0.01,
      spread_bbb: 0.025,
      spread_bb: 0.02,
      spread_b: 0.015,
      spread_ccc: 0.04,
      spread_cc: 0.035,
      spread_c: 0.03,
      spread_hy: 0.05,
      spread_nr: 0.03,
      inflation: 0.02,
      stock_index: 112.73617401825719,
      dividend_yield: 0.03,
      property_index: 127.06407187091135,
      occup_rate: 1,
    },
  ],
}
