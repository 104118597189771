import { createSlice } from '@reduxjs/toolkit'
import { update_all as updateParams } from 'src/views/params/generaux/paramGeneSlice'
import { dataDemo } from './dataDemo'
import { objFromArrays } from 'src/views/commun/UsefulFunc'
const keys = ['a0', 'a1', 'a2', 'a3', 'a4', 'a5', 'a6', 'a7']
const initialState = dataDemo
// liste des lois de rachats structurels
export const ppbSlice = createSlice({
  name: 'ppb',
  initialState,
  reducers: {
    modify: (state, action) => {
      const which = action.payload.which // 'stock' or 'nonAdmis'
      const data = action.payload.data
      return state.map((e) => ({
        ...e,
        [which]: objFromArrays(
          keys,
          data.map((r) => r[e.name]),
        ),
      }))
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateParams, (state, action) => {
      const oldSections = state.map((e) => e.name)
      const newSections = action.payload.sections.filter((e) => !oldSections.includes(e))
      const delSections = oldSections.filter((e) => !action.payload.sections.includes(e))
      if (newSections.length > 0 || delSections.length > 0) {
        const newState = [
          ...state.filter((e) => !delSections.includes(e.name)),
          ...newSections.map((section) => ({
            name: section,
            stock: { a0: 0, a1: 0, a2: 0, a3: 0, a4: 0, a5: 0, a6: 0, a7: 0 },
            non_admis: { a0: 0, a1: 0, a2: 0, a3: 0, a4: 0, a5: 0, a6: 0, a7: 0 },
          })),
        ]
        return newState
      } else {
        return state
      }
    })
  },
})

export const { modify } = ppbSlice.actions
export const getPPB = (state) => state.ppb

export default ppbSlice.reducer
