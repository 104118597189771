export const ptfActifDemo = [
  {
    cat: 'fixed_rate_bond',
    sub_cat: 'corporate',
    section: 'canton general',
    label: 'Solvencii Bonds',
    code_val: 'S_BOND',
    currency: 'EUR',
    hold_to: '2030-09-30',
    issuer: 'Solvencii SAS',
    cat_s2_con: '1',
    type_con: '1',
    cat_s2_spd: '1',
    cost_price: 900000,
    quantity: 1000,
    quote: 120,
    rating: 'AAA',
    premium: 5000,
    nominal: 1000,
    redemption: 1000,
    redemption_date: '2030-09-30',
    issue_date: '2023-09-30',
    freq: 1,
    nominal_yield: 5,
    redemption_mode: 'in_fine',
  },
  {
    cat: 'equity',
    sub_cat: 'listed',
    section: 'canton general',
    label: 'Solvencii Shares',
    code_val: 'S_EQ',
    currency: 'EUR',
    hold_to: '2023-12-31',
    issuer: 'Solvencii SAS',
    cat_s2_con: '1',
    type_con: '1',
    cat_s2_eq: '1',
    cost_price: 150000,
    quantity: 1000,
    quote: 160,
    pdd_init: 0,
    beta: 1,
  },
  {
    cat: 'property',
    sub_cat: '1',
    section: 'canton general',
    label: 'Solvencii Office',
    code_val: 'S_PROP',
    currency: 'EUR',
    hold_to: '2023-12-31',
    issuer: 'Solvencii SAS',
    cat_s2_con: '1',
    type_con: '1',
    cat_s2_prop: '1',
    quote: 150000,
    pdd_init: 0,
    amort_acqui_cost_init: 0,
    amort_constr_init: 0,
    amort_acqui_cost_months: 60,
    amort_constr_months: 180,
    rent: 450,
    charges: 50,
    acqui_cost: 12000,
    constr: 60000,
    land: 50000,
    quantity: 1,
    cost_price: 122000, // so that it will be shown in UI without computed
  },
]
