import { createSlice } from '@reduxjs/toolkit'
import { dataDemo } from './dataDemo'

const zeros = new Array(1800).fill(0)
const initialState = [
  {
    name: 'zeros',
    curveStep: 'monthByMonth',
    llp: 1800,
    last_maturity: 1800,
    va: 0,
    curveType: 'others',
    data: zeros,
    curve_va: zeros,
    curve_wo_va: zeros,
  },
  ...dataDemo,
]
// liste des lois de rachats structurels
export const yieldCurvesSlice = createSlice({
  name: 'yield curves',
  initialState,
  reducers: {
    supprimer: (state, action) =>
      state.filter((e) => !action.payload.map((mp) => mp.name).includes(e.name)),
    dupliquer: (state, action) => {
      var today = new Date()
      var time = today.toUTCString()
      return action.payload
        .map((e) => ({
          ...e,
          name: e.name + '_dupliqué_' + time,
        }))
        .concat(state)
    },
    modifier: (state, action) =>
      state.map((e) => (e.name === action.payload.old.name ? action.payload.new : e)),
    ajouter: (state, action) => [action.payload, ...state],
    addN: (state, action) => [...action.payload, ...state],
    load: (state, action) => (state = action.payload),
  },
})

export const { supprimer, dupliquer, modifier, ajouter, addN, load } = yieldCurvesSlice.actions
export const getYieldCurves = (state) => state.yieldCurves

export default yieldCurvesSlice.reducer
