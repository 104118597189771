import { createSlice } from '@reduxjs/toolkit'
//import { dataDemo } from './dataDemo'

const initialState = []
// historique
export const histoSlice = createSlice({
  name: 'histo',
  initialState,
  reducers: {
    importer: (state, action) => (state = action.payload),
  },
})

export const { importer } = histoSlice.actions
export const getHisto = (state) => state.histo

export default histoSlice.reducer
