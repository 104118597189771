// zero coupon yield curve (courbe de taux zero-coupon)
import { createSlice } from '@reduxjs/toolkit'

const initialState = []
// scenario eco det
export const ctzSlice = createSlice({
  name: 'ctz',
  initialState,
  reducers: {
    importer: (state, action) => (state = [...state, action.payload]),
    dupliquer: (state, action) => {
      var today = new Date()
      var time = today.toUTCString()
      const duplicatedRows = action.payload.map((e) => ({
        ...e,
        name: e.name + '_dupliqué_' + time,
      }))
      return duplicatedRows.concat(state)
    },
    supprimer: (state, action) =>
      (state = state.filter((el) => !action.payload.map((e) => e.name).includes(el.name))),
  },
})

export const { importer, dupliquer, supprimer } = ctzSlice.actions
export const getCtzList = (state) => state.ctzList // in store

export default ctzSlice.reducer
