import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  height: 600,
}

export const consoleSlice = createSlice({
  name: 'console',
  initialState,
  reducers: {
    setHeight: (state, action) => ({ ...state, height: action.payload }),
  },
})

export const { setHeight } = consoleSlice.actions
export const getConsole = (state) => state.console

export default consoleSlice.reducer
