import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  inputs: {},
  erreurs: {},
}

export const inputFormSlice = createSlice({
  name: 'inputForm',
  initialState,
  reducers: {
    setInputs: (state, action) => {
      state.inputs = action.payload
    },
    setErreurs: (state, action) => {
      state.erreurs = action.payload
    },
    inputChange: (state, action) => {
      state.inputs = { ...state.inputs, [action.payload.id]: action.payload.value }
    },
    inputRequired: (state, action) => {
      const payload = action.payload
      const valeur = payload.value.trim()
      if (valeur === '') state.erreurs = { ...state.erreurs, [payload.id]: 'valeur non autorisée' }
      else {
        state.erreurs = { ...state.erreurs, [payload.id]: null }
        state.inputs = { ...state.inputs, [payload.id]: valeur }
      }
    },

    inputNumber: (state, action) => {
      const payload = action.payload
      const floor = payload.floor
      const cap = payload.cap
      const valeur = payload.value.replace(/,/g, '.').replace(/\s+/g, '')
      let valeur1 = NaN
      if (valeur[0] === '=') {
        // evaluer la formule
        try {
          valeur1 = eval(valeur.replace(/=/g, ''))
        } catch (err) {
          // rien
        }
      } else {
        valeur1 = parseFloat(valeur)
      }

      if (isNaN(valeur1)) {
        state.erreurs = { ...state.erreurs, [payload.id]: 'valeur erronée' }
      } else if (valeur1 < floor) {
        state.inputs = { ...state.inputs, [payload.id]: valeur1 }
        state.erreurs = { ...state.erreurs, [payload.id]: 'erreur : valeur < ' + floor }
      } else if (valeur1 > cap) {
        state.inputs = { ...state.inputs, [payload.id]: valeur1 }
        state.erreurs = { ...state.erreurs, [payload.id]: 'erreur : valeur > ' + cap }
      } else {
        state.erreurs = { ...state.erreurs, [payload.id]: null }
        state.inputs = {
          ...state.inputs,
          [payload.id]: valeur1,
        }
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { setInputs, setErreurs, inputChange, inputRequired, inputNumber } =
  inputFormSlice.actions
export const getInputForm = (state) => state.inputForm

export default inputFormSlice.reducer
