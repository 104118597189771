import { createSlice } from '@reduxjs/toolkit'

const initialState = [
  {
    name: 'défaut',
    enc: 0,
    newb: 0,
    vers: 0,
    rach: 0,
    ech: 0,
    dec: 0,
    formule: '',
  },
  {
    name: 'Frais Euro',
    enc: 0.15,
    newb: 5,
    vers: 0.5,
    rach: 1,
    ech: 1,
    dec: 2,
    formule: '',
  },
  {
    name: 'Frais UC',
    enc: 0.2,
    newb: 4,
    vers: 0.4,
    rach: 1,
    ech: 1,
    dec: 2,
    formule: '',
  },
]
// scenario eco det
export const fraisSlice = createSlice({
  name: 'fraisList',
  initialState,
  reducers: {
    importer: (state, action) => (state = [...state, action.payload]), // action.payload = {name:..., ext:..., data:...}
    dupliquer: (state, action) => {
      var today = new Date()
      var time = today.toUTCString()
      const duplicatedRows = action.payload.map((e) => ({
        ...e,
        name: e.name + '_dupliqué_' + time,
      }))
      return duplicatedRows.concat(state)
    },
    supprimer: (state, action) => {
      const delNames = action.payload.map((e) => e.name).filter((e) => e !== 'défaut') // sauf 'défaut'
      return state.filter((el) => !delNames.includes(el.name))
    },
    modifier: (state, action) =>
      state.map((e) => (e.name === action.payload.old.name ? action.payload.new : e)),
    ajouter: (state, action) => [action.payload, ...state],
  },
})

export const { importer, dupliquer, supprimer, modifier, ajouter } = fraisSlice.actions
export const getFraisList = (state) => state.frais // in store

export default fraisSlice.reducer
