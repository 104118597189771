export const dataDemo = [
  {
    name: 'canton general',
    stock: { a0: 3000, a1: 6000, a2: 3000, a3: 5000, a4: 4000, a5: 5000, a6: 2000, a7: 4000 },
    non_admis: { a0: 0, a1: 0, a2: 0, a3: 0, a4: 0, a5: 0, a6: 0, a7: 0 },
  },
  {
    name: 'PER',
    stock: { a0: 0, a1: 0, a2: 0, a3: 0, a4: 0, a5: 0, a6: 0, a7: 0 },
    non_admis: { a0: 0, a1: 0, a2: 0, a3: 0, a4: 0, a5: 0, a6: 0, a7: 0 },
  },
  {
    name: 'PERP',
    stock: { a0: 0, a1: 0, a2: 0, a3: 0, a4: 0, a5: 0, a6: 0, a7: 0 },
    non_admis: { a0: 0, a1: 0, a2: 0, a3: 0, a4: 0, a5: 0, a6: 0, a7: 0 },
  },
]
